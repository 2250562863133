#section-aboutUs{
    
    overflow: hidden;
}

.container-content-aboutUs{
    background: #706EDB;
    color: white;
    margin: 0 10%;
    padding: 20px 60px;
    font-size: 25px;
    align-content: center;
    font-weight: bold;
    position: relative;
    top: -50px;
    border-radius: 15px;
}

.hojas_aboutUs{
    position: absolute;
    z-index: 4;
}

#hoja1_about_us{
    top: 0;
    right: -50px;
}

#hoja2_about_us{
    height: 3rem;
    bottom: -30px;
    left: 75px;
}

.nubes_aboutUs{
    position: absolute;
    z-index: 4;
}

#nube1_aboutUs{
    height: 5rem;
    top: 0;
    left: 75px;
}

#nube2_aboutUs{
    height: 5rem;
    bottom: 25px;
    left: 10px;
}

#nube3_aboutUs{
    height: 6rem;
    bottom: 70px;
    right: -75px;
}

/* Estilos para pantallas de escritorio y laptops */
@media only screen and (min-width: 1024px) {
   
}

/* Estilos para tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
   .container-content-aboutUs{
        margin: 0 5%;
        padding: 20px 30px;
        font-size: 20px;
   }

   #hoja1_about_us{
    right: -20px;
    height: 2rem;
   }

   #hoja2_about_us{
    height: 2rem;
}

#nube1_aboutUs{
    height: 5rem;
    top: -30px;
    left: -55px;
}

#nube2_aboutUs{
    height: 5rem;
    bottom: 0px;
    left: -100px;
}

#nube3_aboutUs{
    height: 6rem;
    bottom: 100px;
    right: -200px;
}
}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
    .container-content-aboutUs{
        margin: 0 5%;
        padding: 20px 30px;
        font-size: 15px;
   }

   #hoja1_about_us{
    right: -20px;
    height: 2rem; }

    #hoja2_about_us{
        height: 2rem;
    }

    #nube1_aboutUs{
        height: 5rem;
        top: -30px;
        left: -55px;
    }
    
    #nube2_aboutUs{
        height: 5rem;
        bottom: 0px;
        left: -100px;
    }
    
    #nube3_aboutUs{
        height: 6rem;
        bottom: 100px;
        right: -200px;
    }
}