.dev-env {
  position: fixed;
  top: 0;
  width: 100%;
  height: 22px;
  font-size: 9px;
  font-weight: bold;
  color: white;
  background-color: red;
  text-align: center;
  z-index: 101;
  transition: 650ms;
}

html {
  scroll-behavior: smooth;
}