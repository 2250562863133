@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900);
body {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  background-color: #EFF3FA;
  color: #666;
}

.boxit-blue {
  color: #424996;
}

.boxit-fucsia {
  color: #E9257D;
}

.boxit-purple {
  color: #935CA2;
}

.boxit-gray {
  color: #d3d3d3;
}

.container {
  max-width: 960px;
}

.row-pointer {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
color: #E9257D;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: white !important;
  background-color: #424996 !important; 
  outline: none !important;
}

/* CUSTOM BUTTON
-------------------------------------------------- */
/* GENERAL */
.btn {
  font-size: 14px;
}

/* BLUE */
.btn-blue {
  background-color: #424996;
  border-color: #424996;
  color: #fff;
}
.btn-blue:focus,
.btn-blue:active,
.btn-blue:active:focus {
  background-color: #252955;
  border-color: #252955;
  box-shadow: 0 0 0 4px #babeee;
  color: #fff;
}
.btn-blue:hover {
  background-color: #2e336a;
  color: #fff;
}
.btn-blue:disabled {
  background-color: #424996;
  color: #fff;  
  border: none;
}
.btn-blue:disabled:hover {
  background-color: #424996;
  color: #fff;  
}
.btn-blue:disabled:active {
  box-shadow: none;
}

/* OUTLINE BLUE */
.btn-outline-blue {
  border-color: #424996;
  color: #424996;
}
.btn-outline-blue:focus,
.btn-outline-blue:active,
.btn-outline-blue:active:focus {
  background-color: #2e336a;
  border-color: #2e336a;
  box-shadow: 0 0 0 4px #babeee;
  color: #fff;
}
.btn-outline-blue:hover {
  background-color: #424996;
  color: #fff;
}
.btn-outline-blue:disabled {
  background-color: #fff;
  border-color: #424996;
  color: #424996;
}
.btn-outline-blue:disabled:hover {
  background-color: #fff;
  border-color: #424996;
  color: #424996;
}
.btn-outline-blue:disabled:active {
  box-shadow: none;
}

/* BTN HIDE FUCSIA */
.btn-hide-blue {
  font-size: 13px;
  height: auto;
  padding: 0;
  color: #424996;
}
.btn-hide-blue:hover {
  color: #6d72ac;
}
.btn-hide-blue:active {
  color: #353e8d;
  box-shadow: 0;
}

/* FUCSIA */
.btn-fucsia {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background-color: #E9257D;
  border-color: #E9257D;
  color: #fff;
}
.btn-fucsia:hover {
  background-color: #b11d5f;
  border-color: #b11d5f;
  color: #fff;
}
.btn-fucsia:focus{
  box-shadow: 0 0 0 4px #ebadc9;
}
.btn-fucsia:active {
  background-color: #821546;
  border-color: #821546;
  box-shadow: 0 0 0 4px #ebadc9;
  color: #fff;
}
.btn-fucsia:disabled {
  background-color: #E9257D;
  border: none;
}
.btn-fucsia:disabled:hover {
  background-color: #E9257D;
  border: none;
}
.btn-fucsia:disabled:active {
  box-shadow: none;
}

/* OUT LINE FUCSIA */
.btn-outline-fucsia {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-color: #E9257D;
  background-color: #FFF;
  color: #E9257D;
}
.btn-outline-fucsia:hover {
  background-color: #E9257D;
  color: #fff;
}
.btn-outline-fucsia:focus {
  box-shadow: 0 0 0 4px #ebadc9;
  color: #E9257D;
}
.btn-outline-fucsia:active {
  background-color: #821546;
  border-color: #821546;
}
.btn-outline-fucsia:focus:hover {
  color: #fff;
}
.btn-outline-fucsia:disabled {
  border-color: #E9257D;
  background-color: #FFF;
  color: #E9257D;
}
.btn-outline-fucsia:disabled:hover {
  border-color: #E9257D;
  background-color: #FFF;
  color: #E9257D;
}
.btn-outline-fucsia:disabled:active {
  box-shadow: none;
}

/* BTN HIDE FUCSIA */
.btn-hide {
  font-size: 13px;
  height: auto;
  padding: 0;
  color: #E9257D;
}
.btn-hide:hover {
  color: #b6115b;
}
.btn-hide:active {
  color: #660732;
  box-shadow: 0;
}


/* FUCSIA */
.btn-purple {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background-color: #935CA2;
  border-color: #935CA2;
  color: #fff;
}
.btn-purple:hover {
  background-color: #6d4379;
  border-color: #6d4379;
  color: #fff;
}
.btn-purple:focus{
  box-shadow: 0 0 0 4px #d5afe2;
}
.btn-purple:active {
  background-color: #593763;
  border-color: #593763;
  box-shadow: 0 0 0 4px #d5afe2;
  color: #fff;
}
.btn-purple:disabled {
  background-color: #935CA2;
  border: none;
}
.btn-purple:disabled:hover {
  background-color: #935CA2;
  border: none;
}
.btn-purple:disabled:active {
  box-shadow: none;
}

/* OUT LINE FUCSIA */
.btn-outline-purple {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-color: #935CA2;
  background-color: #FFF;
  color: #935CA2;
}
.btn-outline-purple:hover {
  background-color: #935CA2;
  color: #fff;
}
.btn-outline-purple:focus {
  box-shadow: 0 0 0 4px #d5afe2;
  color: #935CA2;
}
.btn-outline-purple:active {
  background-color: #593763;
  border-color: #593763;
}
.btn-outline-purple:focus:hover {
  color: #fff;
}
.btn-outline-purple:disabled {
  border-color: #935CA2;
  background-color: #FFF;
  color: #935CA2;
}
.btn-outline-purple:disabled:hover {
  border-color: #935CA2;
  background-color: #FFF;
  color: #935CA2;
}
.btn-outline-purple:disabled:active {
  box-shadow: none;
}

/* CUSTOM TOOLTIP
-------------------------------------------------- */
.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .custom-tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: white;
  text-align: center;
  border: 1px solid #E9257D;
  border-radius: 6px;

  /* Position the tooltip */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -175px;
  z-index: 1;
}

@media only screen and (max-width: 576px) {
  .custom-tooltip .custom-tooltiptext {
    margin-left: -190px;
  }
}

.custom-tooltip:hover .custom-tooltiptext {
  visibility: visible;
}

.dashboardCard {
  padding: 20px;
  margin: 15px;
  background: #FFFFFF;
  color: #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.139);
  border-radius: 11px;
  text-align: center;
}

.dashboardCardAltern {
  padding: 20px;
  margin: 15px;
  background: #000000;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.139);
  border-radius: 11px;
  text-align: center;
  border-left: 6px solid #F9ED32;
}

/* Rectangle 22 */




.darkCard {
  padding: 20px;
  background-color: #303141;
  margin: 15px;
  border: 1px solid #bababa;
  box-shadow: 0 0 20px 1px #cacaca;
  border-radius: 30px;
}

.dashboardCard2 {
  background-color: #fff;
  height: 100%;
  min-width: 100%;
  border: 1px solid #bababa;
  box-shadow: 0.1rem 0.1rem 0.5rem #cacaca;
}

/* CUSTOM FONT
-------------------------------------------------- */
.font-xxsmall {
  font-size: 8px;
}

.font-xsmall {
  font-size: 10px;
}

.font-small {
  font-size: 14px;
}
.text-on-hold {
  color: #FABB00;
}

.btn-primary-llego{
  background-color: #000000;
  color: #F9ED32;
}

.btn-primary-llego:hover{
  background-color: #000000;
  border: 2px solid #F9ED32;
  color: #F9ED32; 
}

.btn-secondary-llego{
  border: 1px solid #C2C2C2;
  color: #C2C2C2;
}

.btn-secondary-llego:hover{
  background-color: #000000;
  border: 2px solid #F9ED32;
  color: #F9ED32; 
}

.btn-third-llego{
  background-color: #000000;
  color: #ffffff;
}

.btn-third-llego:hover{
  background-color: #000000;
  color: #F9ED32;
}

.btn-yellow-llego{
  font-weight: bold;
  background-color: #F9ED32;
  color: #000000;
}

.btn-yellow-llego:hover{
  background: transparent;
  border: 2px solid #F9ED32;
  color: #F9ED32; 
}

.btn-black-llego{
  font-weight: bold;
  background-color: black;
  color: #ffffff;
}

.btn-black-llego:hover{
  background: black;
  border: 2px solid white;
  color: #F9ED32; 
}

.glass-effect{
  display: inline-block;
  background: rgb(4 0 0 / 40%); /* Color blanco con opacidad */
  border-radius: 15px;
  padding: 10px 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); /* Aplicar desenfoque */
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.dev-env {
  position: fixed;
  top: 0;
  width: 100%;
  height: 22px;
  font-size: 9px;
  font-weight: bold;
  color: white;
  background-color: red;
  text-align: center;
  z-index: 101;
  transition: 650ms;
}

html {
  scroll-behavior: smooth;
}
/* CUSTOMIZE THE FORM TO LOGIN
-------------------------------------------------- */
.signin-form {
    max-width: 100%;
    width: 400px;
    margin: auto;
    background-color: white;
    border: 1px solid;
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
    border-radius: 40px;
    padding: 3em;
    border-color: #fdf3ef;
}

@media (max-width: 30em) {
    .signin-form {
      padding: 2.5em;
      margin: 20px;
    }
}
/* LOGIN */
.login {
    width: 100%;
    overflow-y: auto;
    padding: 50px 15px;
    height: 100vh;
}

.signUp {
    width: 100%;
    overflow-y: auto;
    padding: 50px 15px;
}

.input-signin {
    font-size: 15px;
    min-width: 48px;
    font-weight: 400;
}
.input-signin:focus {
    border-color: #808a81;
    box-shadow: 0 0 0 1px #4285F4;
}
  
.link {
    color: #424996;
}
.link:hover {
    text-decoration: none;
    color: #2e336a;
}
  
/* CUSTOM BUTTON */
.btn-login {
    font-size: 15px;
    font-weight: 600;
    min-height: 45px;
}

.login-form {
    max-width: 100%;
    width: 500px;
    margin: auto;
    background-color: rgb(255, 255, 255); 
    padding: 2rem;
    border-radius: 24px;
    box-shadow: 0px 0px 20px rgba(127, 127, 127, 0.2); 
}
.btn-traductor{
    background-color: #000000;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: #F9ED32 ;
}

.btn-traductor:hover{
    background-color: #f8f9fa;
    color: #000;
    border-color: #f8f9fa;
    box-shadow: 0 0 0 0.2rem rgba(248,249,250,.5);
    cursor: pointer;
}

.modal-custome{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.735);
    color: white;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    z-index: 999;
}

.modal-custome-content{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.modal-custome-card{
    background-color: #FFFFFF;
    color: #576AA4;
    padding: 20px 40px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-size: 16px;
    font-weight: bold;
}

.modal-custome-card:hover{
    border-color: #f8f9fa;
    color: rgb(0, 21, 255);
    box-shadow: 0 0 0 0.2rem rgba(248,249,250,.5);
    cursor: pointer;
}

.modal-custome-card img{
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
}

@media (max-width: 610px) {
    .modal-custome-content {
      flex-direction: column; 
    }
  
    .modal-custome-card {
      width: 80%;
      margin: 15px auto;
      padding: 20px 40px;
      flex-direction: row;
      justify-content: space-around; 
    }

    .modal-custome-card span{
        font-size: 16px;
    font-weight: bold;
      }
  }

  .btn-traductor-nav{
    padding: 8px;
    border-radius: 50px;
}

.arrow-down{
    background-color: #e4e4e482;
    padding: 4px;
    border-radius: 50%;
    fill: #424996;
}

.name-translate{
    font-weight: 600;
    color: #F9ED32;
}

.drop-traduct > .dropdown-item:hover{
    cursor: pointer;
    background: #007bff;
    color: white;
}


.dropdown-item:hover{
    cursor: pointer;
    background: #000000;
    color: #f9ed32;
}
.signup-form {
    max-width: 100%;
    margin-top: 150px;
    width: 500px;
    margin: auto;
    background-color: white;
    border: 1px solid;
    border-radius: 46px;
    padding: 2rem;
    border-color: #fdf3ef;
    filter: drop-shadow(0px 2px 2px rgba(96, 2, 246, 0.332));
}

.title-signup-1 {
    font-size: 32px;
    color: black;
    margin-bottom: -12px;
    margin-left: 22px;
}
.title-signup-2 {
    font-size: 32px;
    color: black;
    margin-left: 22px;
}

.subtitle-signup {
    font-size: 12px;
}

.purple-left-line {
    border-left: solid 2px #424996;
    height: 35px;
}

.fucsia-left-line {
    border-left: solid 2px #e9257d;
    height: 25px;
}

@media (max-width: 30em) {
    .signup-header {
        margin-top: 15px;
    }
    
    .title-signup-1 {
        font-size: 20px;
        color: black;
        margin-bottom: -7px;
        margin-left: 15px;
    }
    .title-signup-2 {
        font-size: 20px;
        color: black;
        margin-left: 15px;
    }
    
    .subtitle-signup {
        font-size: 10px;
    }
    
    .purple-left-line {
        border-left: solid 2px #424996;
        height: 25px;
    }
    
    .fucsia-left-line {
        border-left: solid 2px #e9257d;
        height: 15px;
    }

    .signup-form {
      padding-top: 1em;
      margin: 10px;
    }

    .signup-container {
        width: 100%;
    }
}

/* CUSTOM BUTTON */
.btn-signup {
    font-size: 15px;
    font-weight: 600;
    min-height: 40px;
    min-width: 150px;
}

/* Stepper.css */
.step-head {
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 0 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.stepper-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative; /* Agregar posición relativa */
    z-index: 1;
  }
  
  .step-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #ccc;
  background-color: #eeeeee;
  font-size: 8px;
}
  
  .step-circle.active {
    border-color: #3F4AC5;
    color: #3F4AC5;
  }

  .step-title {
    font-size: 13px;
    color: #CACACC;
    margin-top: -25px;
  }

  .step-title:hover {
    cursor: pointer;
  }

  .step-title.active {
    color: #3f4ac5;
    font-weight: 600;
  }

  .progress-bar{
    background-color: #3F4AC5;
}

.progress-container {
    width: calc(100% - 50px); 
    position: relative;
    top: -10px;
    z-index: 0;
  }

  .progress {
    width: 100%;
    background-color: #D9D9D9;
  }

  
  /*Buttons Plans*/
  .cards-plans{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
  }

    .btn-plan {
      cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        font-size: 15px;
        margin: 10px;
        padding: 25px 30px;
        color: #ADADAD;
        border: 1px solid;
       border-color: #D8D8D8;
       position: relative;
       
    }

    .btn-plan.active{
        color: #3F4AC5;
        font-weight: 600;
        border: 1px solid;
       border-color: #6B73CF;
    }

    .btn-plan-icon{
        width: 27px;
        height: 27px;
        margin-bottom: 10px;
    }

    .option-circle{
      position: absolute;
      top: 7px;
      right: 7px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: solid 1px #D8D8D8;
    }

    .option-circle.active{
      border: solid 5px #3F4AC5;
    }

.form-check-label{
  cursor: pointer;
}


.link-span {
  cursor: pointer;
  color: #343a40; 
}

.link-span:hover {
  color: #0056b3; 
  text-decoration: underline; 
}

/* BLOQUEO DE PLANES */
.btn-plan{
  cursor: not-allowed;
}
.btn-plan.active{
  cursor: pointer;
}


/* LoadingScreen.css */
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading-spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
.selector-country{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .card-country{
    display: flex;
    align-items: center;
    padding: 6px 8px;
    margin: 0px 5px;
    border: 1px solid #c6c6c6;
    border-radius: 25px;
    color: #c6c6c6;
  }
  
  .card-country.active{
    background-color: #424996;
     color: white;
     opacity: 1;
     cursor: pointer;
   }
   
  .card-country img{
    width: auto;
    height: 25px;
    margin-right: 5px;
    background-color: #E1E1E1;
    padding: 2px;
    border-radius: 50%;
  }

  /* estilos temporales */
  .card-country{
    border: 0;
background-color: #d7d7d7;
color: rgba(245, 245, 245, 0.737);
opacity: 0.50
  }

  .card-country.active:hover{
    cursor: pointer;
  }

  .card-country.non-selected:hover{
    opacity: 0.75;
    cursor: pointer;
  }
  
  .card-country.disabled{
    opacity: 0.50;
    cursor: not-allowed;
  }

 

 
.forgot-form {
    max-width: 100%;
    width: 600px;
    margin: auto;
    background-color: #eeeeee;
    border-radius: 46px;
    padding: 2em;
}

@media (max-width: 630px) {
  .forgot-form {
    padding-top: 2em;
    margin: 20px;
  }
}

/* CUSTOM BUTTON */
.btn-forgot {
  font-size: 15px;
  font-weight: 600;
  min-height: 40px;
  min-width: 150px;
  margin: 0.5em;
}
.reset-form {
    max-width: 100%;
    width: 600px;
    margin: auto;
    background-color: #eeeeee;
    border: 1px solid;
    border-radius: 46px;
    padding: 2em;
    border-color: #fdf3ef;
}

@media (max-width: 630px) {
  .reset-form {
    padding-top: 2em;
    margin: 20px;
  }
}

/* CUSTOM BUTTON */
.btn-reset {
  font-size: 15px;
  font-weight: 600;
  min-height: 40px;
  min-width: 150px;
  margin: 0.5em;
}

#span-eye-pwd:hover, #span-eye-pwdConfirm:hover {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}
.business-signup-date {
    font-size: 11px;
    font-weight: 600;
}

.font-bold {
    font-weight: 600;
}

.btn-update {
    width: 50px;
}

.span-eye-pwd:hover {
    background-color: #d8d8d8;
    border-color: #d8d8d8;
}
.home-subtitle {
  font-size: 16px;
}

.text-bold {
  font-weight: 600;
}

.home-container {
  background-color: #fff;
  margin: 15px;
  border: 1px solid #bababa;
  box-shadow: 0 0 20px 1px #dfdfdf;
}

.graph {
  height: 100%;
  min-width: 100%;
}

.graph-container {
  height: 200px;
  width: 90%;
}

.card-info {
  height: 50%;
  min-height: 100px;
}

.card-info-vertical {
  height: 100%;
  min-height: 150px;
}

.charts-container {
  min-height: 200px;
  min-width: 220px;
}

/* CUSTOM SPINNNER
-------------------------------------------------- */
.td-spinner {
  height: 120px;
}

.axw {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.azi {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

.ajg {
  background-color: rgb(74 222 128 / 0.1);
}

.akr {
  background-color: rgb(251 113 133 / 0.1);
}

.uo {
  flex: none;
}

.ads {
  border-radius: 9999px;
}

.aqm {
  padding: 0.25rem;
}

.ne {
  height: 0.375rem;
}

.rf {
  width: 0.375rem;
}

.ahy {
  background-color: currentColor;
}

.lx {
  display: flex;
  justify-items: left;
}

.yz {
  align-items: left;
}

.zd {
  justify-content: flex-start;
}

.aac {
  -moz-column-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  column-gap: 0.5rem;
}

@media (min-width: 640px){
  .cbl {
    justify-content: flex-center;
}
}

.axw-online {
  --tw-text-opacity: 1;
  color: rgb(13, 151, 243, var(--tw-text-opacity)); /* Blue color */
}

.azi-offline {
  --tw-text-opacity: 1;
  color: rgb(169, 169, 169, var(--tw-text-opacity)); /* Gray color */
}

.ajg-online {
  background-color: rgba(13, 151, 243, 0.1); /* Light blue background */
}

.akr-offline {
  background-color: rgb(169, 169, 169, 0.1); /* Light gray background */
}

.axw-yellow {
  --tw-text-opacity: 1;
  color: rgb(255, 223, 85, var(--tw-text-opacity)); /* Yellow color */
}

.ajg-yellow {
  background-color: rgba(238, 204, 55, 0.152); /* Light yellow background */
}

.btnStatusPlatform:hover{
    text-decoration: underline;
    cursor: pointer;
}
.btnStatusPlatform:hover{
    text-decoration: underline;
    cursor: pointer;
}
.graph-container {
    height: 200px;
    width: 90%;
}

.card-info {
    height: 50%;
}

.card-info-vertical {
    height: 100%;
}

.charts-container {
    min-height: 200px;
    min-width: 220px;
}
#homeOptions {
    min-height: calc(100vh - 220px);
}

#homeOptions > div {
    border-radius: 1rem;
}

.btnCounterHome {
    font-size: 1.2rem;
    width: 10rem;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
}
.faqs-container {
  background-color: #F0F5F5;
  margin: 15px;
  padding: 15px;
}

#cover-faqs {
  background-image: linear-gradient(to bottom left, #424996, #303141 80%);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: #fff;
}

.question-container {
  background-color: #fff;
  padding: 25px;
  color: black;
  border-radius: 8px;
  border-bottom: solid 2px #dae1f5;
 
}

.questions {
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  border-radius: 15px;
}
.questions:hover {
  color: #F9ED32;
}

.q-title {
  font-size: 20px;
  font-weight: 200
}

.faqs-content .row {
  padding: 0 10%; 
}

.circle-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.faqs-title{
  color:#6E6DD7;
  font-size: 40px;
}

.question-icon{
  color: #6E6DD7;

}

.btn-pagination{
  color: #c9c9c9;
  font-weight: bold;
  border: 1px solid #c9c9c9;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center
}

.btn-pagination:hover{
  color: #6E6DD7;
  cursor: pointer;
  border: 1px solid #6E6DD7;
}

.pagination-faqs{
  display: flex;
  justify-content: center;
  align-items: center;
grid-gap: 10px;
gap: 10px;
}

.container-html-faqs ul{
    list-style-type: circle;
    margin-left: 20px;
}

@media (max-width: 768px) { 
  .faqs-content .row {
    padding: 0; 
  }
}
.dashboard {
  background-color: #EFF3FA;
  text-align: center;
}

.loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #E9257D;
  font-size: 55px;
}

.main-layout-open {
  position: fixed;
  top: 60px;
  left: 80px;
  margin: 0;
  padding: 0;
  width: calc(100% - 80px);
  height: calc(100vh - 60px);
  transition: 650ms;
  overflow-y: auto;
  overflow-x: hidden;
}
.main-layout {
  position: fixed;
  top: 60px;
  left: 80px;
  margin: 0;
  padding: 0;
  width: calc(100% - 80px);
  height: calc(100vh - 60px);
  transition: 650ms;
  overflow-y: auto;
  overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
  .main-layout-open {
    position: fixed;
    top: 60px;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: calc(100vh - 60px);
    transition: 650ms;
    opacity: 0.5;
    overflow-y: auto;
  }
  .main-layout {
    position: fixed;
    top: 60px;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: calc(100vh - 60px);
    transition: 650ms;
    overflow-y: auto;
  }
}


.main-uploader{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed;
    border-color: rgb(210, 210, 210);
    border-radius: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    border-radius: 8px;
    padding: 20px 50px;
    color: white;
    background-color: black;
}
.search-filters {
  width: 450px;
  margin: 0 auto;
  box-sizing: border-box;
  width: auto;
}

.search-filters-inputs {
  display: flex;
  flex-direction: column;
  position: relative;
}

.search-filters-select {
  width: 150px;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon-container{
  position: absolute;
  z-index: 1;
  left: 15px;
}

.search-icon{
  pointer-events: none;
  color: gray;
}

.search-icon svg{
  color: gray;
}

.search-filters-input {
  flex-grow: 1;
  padding: 10px 15px 10px 40px; /* Ajusta el padding para hacer espacio para el icono */
  border: 1px solid #ccc;
  border-radius: 50px;
  outline: none;
  position: relative;
}


.search-filters-results {
  position: absolute;
  -webkit-user-select: none;
          user-select: none;
  top: 40px;
  list-style-type: none;
  padding: 5px 0;
  border: 1px solid #ccc;
  background: white;
  width: 100%;
  z-index: 15;
  border-radius: 8px;
  margin: 0;
  box-shadow: 0 1px 2px rgba(85, 85, 85, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(171, 171, 171, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

.search-filters-results li {
  padding: 5px;
  border-bottom: 1px solid #dddddd8b;
  text-align: left;
  padding-left: 25px;
  border-radius: 4px;
}

.search-filters-results li:hover {
  background-color: #424996;
  color: #ffffff;
  cursor: pointer;
}

.selected-filters{
  display: flex;
  flex-direction: column  ;
  grid-gap: 10px;
  gap: 10px;
}

.selected-filter {
  position: relative;
  margin-top: 10px;
  background: rgb(255, 255, 255);
  padding: 5px 25px;
  background-color: #4249960a;
  width: 100%;
  /* box-shadow: rgba(92, 92, 92, 0.21) 0px 0px 10px; */
  border: 1px solid #d7d7d7;
  border-style: dashed;
  border-radius: 16px;
}

.text-under-ref{
  text-decoration: underline;
}

.text-under-ref:hover{
  cursor: pointer;
  color: #3a49ef;
}
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }

  .grid-item {
    background-color: #ffffff;
    text-align: center;
    /* border: 1px solid #ccc; */
    color: rgb(93, 91, 91);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(92, 92, 92, 0.211);
    /* word-wrap: break-word;  */
    word-break: break-word; 
    /* overflow-wrap: break-word; */
  }

  .grid-item:hover{
    box-shadow: 0 0 10px #4756ffa8;
    color: rgb(93, 91, 91);
    cursor: pointer;
  }

  .img-item-canvas{
    display: flex;
    align-items: center;
    background-color: #000000;
    color: #F9ED32;
    border-radius: 8px 0 0 8px;
    padding: 15px;
  }

  .img-item-canvas.fucsia{
    background-color: #e9257d;
    color: #ffffff;
  }

  .description-item-canvas{
    padding: 15px;
    text-align: start;
  }

  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }

  @media (max-width: 480px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
  }


  .clients-container {
    background-color: #ffffff;
    margin: 20px;
    border: 1px solid #bababa;
    box-shadow: 0 0 20px 1px #dfdfdf;
  }
  
  .graph {
    height: 100%;
    min-width: 100%;
  }
  
  .link-hover-effect{
    color: #424996;
    transition: color
  }
  .link-hover-effect:hover {
    color: #4c5bff;
  }
  
  .box-card-general {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;  padding: 25px;
    background-color: white;
    border-radius: 10px;
  }
  
  .details-section-option{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding:  0  25px 0 0;
    border-right: 3px solid #f6f6f6;
    width: 15rem;
    grid-gap: 20px;
    gap: 20px;
  }
  
  .details-section-information{
    padding: 0 25px;
    width: 100%;
  }
  
  .box-card-general-dash{
    margin: 10px 0;
    padding: 25px;
    background-color: white;
    border-radius: 10px;
    border: 1px dashed rgba(70, 93, 99, 0.576);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
      flex-direction: column;
      justify-content: center;
  }
  
  .details-section-option-item.active {
    color: #4c5bff;
    background: #e9f0ff;
    padding: 10px;
    border-radius: 25px;
    font-weight: 500;
    border: none
  }
  
  .details-section-option-item {
    color: #465d6393;
    border-radius: 25px;
    padding: 10px;
    border: 1px dashed rgba(101, 110, 113, 0.287);
    display: flex;
    justify-content: center;
    font-size: 12px;
    grid-gap: 12px;
    gap: 12px;
    width: 100%;
  }
  
  .details-section-option-item:hover {
    color: #4c5bff;
    cursor: pointer;
    border: 1px dashed #4c5bff;
  }
  
  .details-section-option-item.active:hover {
    color: #4c5bff;
    cursor: default;
    border: none;
  }
  
  .details-profile-icon {
              width: 65px;        
              height: 65px;        
              border-radius: 50%;   
              background-color: #424996;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
  }
  
  
  .details-info-response{
    color: #777778 ;
    font-weight: 500;
  }
  
  .details-info-label{
    color: #afb6c3
  }
  
  .details-info{
    margin: 15px 0;
    font-size: 14px;
  }
  
  .badge-status{
    color: #4c5bff;
    background: #e9f0ff;
    padding: 5px 10px;
    border-radius: 25px;
    font-size: 12px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 500;
    margin-bottom: 0;
    /* box-shadow: rgba(186, 185, 185, 0.2) 0px 7px 10px 0px; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  
  .badge-status.success{
    color: green;
    background: #efffe9;
  }
  
  .badge-status.warning{
    color: rgb(193, 162, 3);
    background: #fffee9;
  }
  
  .badge-status.alert{
    color: rgb(222, 5, 5);
    background: #ffe9e9;
  }
  
  .badge-status.fucsia{
    color: #e9257d;
    background: #ffebf4;
  }
  
  .details-section-general {
    display: flex;
    flex-direction: row;
  }
  
  .header-filter-box{
    display: flex;
    justify-content: space-between;
  }
  
  #details-options-viewactions{
    border: 1px solid #d8d8d8;
    color: #666666;
    border-radius: 8px;
    font-size: 13px;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
  }
  
  #details-options-viewactions:hover{
    background-color: #f5f5f5;
    cursor: pointer;
  }
  
  .panel-active>.dropdown-menu {
    height: auto;
    display: block;
  }
  
  .dropdown-menu {
    position: absolute;
    /* height: 0; */
    right: 0;
    top: 60px;
    background-color: #F5F5F5;
  }
  
  .details-section-info-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
  }
  
  #subtitle-createClient{
    display: block;
  }
  
  /* Estilos para pantallas pequeñas (hasta 600px de ancho) */
  @media (max-width: 600px) {
  
    .details-section-general{
      flex-direction: column;
    }
  
    .details-section-option {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      padding: 0  0 15px 0;
      border-bottom: 3px solid #f6f6f6;
      border-right: 0px;
      width: 100%;
      grid-gap: 15px;
      gap: 15px;
      margin-bottom: 25px;
  }
  
  .details-profile-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  
  .details-section-information{
    padding: 0 0;
    width: 100%;
  }
  
  .header-filter-box{
    display: flex;
   flex-direction: column;
  }
  
  .details-section-info-group{
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
  }
  
  #subtitle-createClient{
    display: none;
  }
  
  
  }
  
  
  /* Estilos para pantallas medianas (de 601px a 1024px de ancho) */
  @media (min-width: 601px) and (max-width: 1024px) {
  
    .details-section-general{
      flex-direction: column;
    }
  
    .details-section-option {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      padding: 0  0 15px 0;
      border-bottom: 3px solid #f6f6f6;
      border-right: 0px;
      width: 100%;
      grid-gap: 15px;
      gap: 15px;
      margin-bottom: 25px;
  }
  
  .details-profile-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  
  .details-section-information{
    padding: 0 0;
    width: 100%;
  }
  
  .header-filter-box{
    display: flex;
   flex-direction: column;
  }
  
  .details-section-info-group{
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
  }
  
  }
  
  /* Estilos para pantallas grandes (más de 1024px de ancho) */
  @media (min-width: 1025px) {
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    justify-items: center;
    width: 100%;
    margin: 20px 0;
  }
  
  .pagination-btn {
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px 10px;
    margin: 0 2px;
    cursor: pointer;
  }
  
  .pagination-btn.active {
    background-color: #343A4F;
    color: white;
  }
  
  .pagination-btn:disabled {
    background-color: #f1f1f1;
    cursor: not-allowed;
  }

:root{
  --bg-shape-color:linear-gradient(120deg,  #343A4F, #0F1620) ;
 --lightblue: #F9ED32;
 --darkblue: #f0c800;
 --text-color: #D5E1EF;
}

.item-package{
    color: var(--text-color);
  border-radius: 40px;
  background-image: var(--bg-shape-color);
  overflow: hidden;
  max-width: 450px;
  height: 100%;
}
.overviewInfo, .productSpecifications {
  padding: 14px;
}
.overviewInfo{
  background-image:  linear-gradient(176deg, var(--lightblue), var(--darkblue));
}

.overviewInfo-borrador{
  padding: 14px;
  background: #a5a5a5;
}

.actions{
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  
  .cartbutton{
    position:relative;
  }
  .cartbutton::after {
      content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-image: linear-gradient(90deg, var(--lightblue), var(--darkblue));
    border-radius: 50%;
    position: absolute;
    top: 11px;
    right: 8px;
}
  
  .cartbutton svg {
    color: #ababab73;
}
  .backbutton, .cartbutton{
    
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
.neurobutton{
  background-image: var(--bg-shape-color);
   
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 3px 4px 5px 0px rgba(197, 197, 197, 0.1), inset 3px 6px 6px 5px rgba(78, 77, 77, 0.1), -2px -2px 8px 2px rgba(255,255,255,0.1), 2px 2px 6px 3px rgba(0,0,0,0.4);
}

.productinfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    margin-bottom: 50px;
}

.productImage {
    position: absolute;
  
    width: 321px;
    height: auto;
    transform: rotate(-90deg) translate(-56px, 66px);
  
 animation: updowncontroller infinite 3s alternate;
img{ width: 100%; height: auto}
  transition: ease 2s all ;
  &:hover{
      transition: ease 2s all ;
    animation: none;
    transform: rotate(-70deg) translate( 10px, 66px);
  }
}
 

h1{
  font-family: 'Michroma', sans-serif;
}
.grouptext{
h3 {
    letter-spacing: 3.2px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}
  p {
    font-size: 12px;
    opacity: 0.8;
  } 
  
}


/* product specifications */
.feature{
    display: flex;
    align-items: center;
    padding: 0 5%;
    margin-bottom: 8px;
}

.feature-title{
    display: flex;
    grid-gap: 3px;
    gap: 3px;
    align-items: center;
    flex-direction: row;
}

.featureIcon{
  width: 40px;
  height: 40px;
  background-image: var(--bg-shape-color);
  border-radius: 8px; 
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.productSpecifications{

  h1{
    margin-top: 10px; 
    margin-bottom: 16px;
    font-size: 32px;
  }
  
  p {
    opacity: 0.8;
    font-size: 15px;
    line-height: 1.5;
}
  
  .productFeatures {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
  }
}

.productSpecifications{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 10%;
}



.checkoutButton {
  width: 100%;
  background-image: var(--bg-shape-color);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: -2px -2px 2px 0px rgba(80, 80, 80, 0.1), 2px 2px 3px 0px rgba(12, 12, 12, 0.3), inset 0px 0px 0px 2px rgba(80, 80, 80, 0.2);
  padding: 14px;
}

.priceTag {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.priceTag span {
    font-size: 14px;
}


/* checkout button*/
.preorder{
    display: flex;
    align-items: center;
    justify-content: center;
}

button.preorder {
    outline: 0;
    border: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-image: linear-gradient(85deg, var(--lightblue), var(--darkblue));
    color: #0F1620;
}

button.preorder:hover {
 border: 1px solid #f0cc00;
}


.preorder {
    padding: 8px 17px;
    border-right: 1px solid rgba(0,0,0,0.4);
    color: black;
}

.buttonaction {
    border-left: 1px solid rgba(255,255,255,.2);
    padding: 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255,255,255,0.7);
}




/* animation */

@keyframes updowncontroller{
  0%  {
    transform: rotate(-90deg) translate(-56px, 66px);}
  80%, 100%{
    transform: rotate(-70deg) translate( 10px, 66px);}
}

.expandButton:hover{
  color: #f0c800;
  cursor: pointer;
}
.activate-form {
    max-width: 100%;
    width: 400px;
    margin: auto;
    background-color: #eeeeee;
    border: 1px solid;
    border-radius: 46px;
    padding: 3em;
    border-color: #fdf3ef;
    color: black;
}

@media (max-width: 30em) {
    .signin-form {
      padding: 2.5em;
      margin: 20px;
    }
}
.navbar {
  background: transparent;
  /* background-color: red; */
  width: 100%;
  height: 74px;
  display: flex;
  justify-content: space-around;

  align-content: center;
  font-size: 1.1rem;
  padding: 15px 30px;
  /* border-bottom: 2px solid #d5d5d5a0; */
  position: fixed;
  top: 0;
  z-index: 1000; 
  font-size: 1rem;
}

.navbar-scrolling{
  transition: background-color 0.5s ease; 
  background-color: black;
}

.logo-scrolling {
  width: 100px;
}

.nav-links-scrolling {
  padding: 0.5rem 0; 
}

.none-links{
  text-decoration: none;
}

.btn-login-scrolling, .btn-register-scrolling {
  padding: 0.25rem 0.5rem; 
  font-size: 0.9rem; 
}

.navbar-logo {
  color: #f9ed32;
  justify-self: center;
  margin-left: 20px;
  cursor: pointer;
}

.fa-react {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  grid-template-columns: repeat(4, auto);
  grid-gap: 20px;
  gap: 20px;
  list-style: none;
  text-align: center;
  justify-content: center;
  align-content: center;
  display: flex;
  align-items: center;
  z-index: 999;
}

.nav-links.active {
  color: #f9ed32;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

.nav-links.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 4px;
  background-color: #f9ed32;
  border-radius: 8px;
}


.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #f9ed32;
  color: black;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  text-decoration: none;
}
.nav-links.active{
  color: white;
}

.nav-links.active:hover {
  color: black;
  background-color: #f9ed32;
  padding: 0.5rem 1rem;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  color: #f9ed32;
}


.btn-login {
  background-color: transparent;
  color: #ffffff;
  border: solid 1px transparent;
  border-radius: 25px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease-out;
  text-decoration: none;
  font-size: 1rem;
  text-decoration: none;
}

.btn-register {
  color: #f9ed32;
  background-color: transparent;
  border: solid 1px #f9ed32;
  border-radius: 25px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease-out;
  text-decoration: none;
  font-size: 1rem;
  text-decoration: none;
}

a {
  text-decoration: none;
}

.btns-login-mobile{
  display: none;
}

/* Mobile NAV */
.navbar-mobile{
  background: #121212;
  color: #fff;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: fixed;
  inset-inline-end: 0px;
  top: 0px;
  padding: 40px 16px 16px;
  min-width: 320px;
  max-width: 100vw;
  z-index: 998;
  transition: transform 0.3s ease-out; 
  transform: translateX(100%);
}

.navbar-mobile.active {
  transform: translateX(0); /* Move on-screen */
}

.navbar-mob-background {
  top: 0;
  left: 0;
  position: fixed;
  background: #000000b9;
  width: 100vw;
  height: 100%;
  display: none; 
  opacity: 0;
  transition: opacity 0.3s ease-out; 
  /* Transition for fading effect */
}

.navbar-mob-background.active {
display: block;
opacity: 1;
}



.navbar-mob-content{
  z-index: 980;
}

.navbar-mob-options{
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;

}

.navbar-mob-section {
  background-color:#ffffff12;;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
}

.navbar-mob-item {
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
  text-decoration: none;
  color: inherit;  
}

.navbar-mob-item:hover {
  color: #f9ed32;
  cursor: pointer;
}

.navbar-mob-item-title {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.icon-section-mob{
  background-color: #ffffff12;
  border-radius: 4px;
  display: inline-flex;
  padding: 8px;
}


@media (max-width: 1110px) {

  .navbar {
    justify-content: space-between;
  }

  .nav-menu {
    display: none
  }

  .nav-menu.active {
    border-radius: 0 0 16px 16px;
    padding: 25px;
    margin-top: 20px;
    height: auto;
    background: #f9ed32;
    left: 0;
    top: 80px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 3px;
    width: 100%;
    display: table;
    color: white;
    font-weight: 200;
    transition: background-color 0.5s, color 0.5s, border-radius 0.5s, padding 0.5s;
  }

  .nav-links:hover {
    background-color: white;
    color: #f9ed32;
    border-radius: 50px;
    padding: 15px;
  }

  .nav-links.active {
    color: white;
    padding: 0.5rem 1rem;
  }

  .menu-icon {
    z-index: 999;
    display: block;
    position: absolute;
    top: -15px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1rem;
    border-radius: 50px;
    background: white;
    color: #f9ed32;
    text-decoration: none;
    font-size: 1rem;
    margin: 2px;
  }

  .nav-links-mobile-alt {
    display: block;
    text-align: center;
    padding: 1rem;
    border-radius: 50px;
    color: white;
    border: 1px solid white;
    background-color: #f9ed32;
    text-decoration: none;
    font-size: 1rem;
    margin: 2px;
  }

  hr {
    color: #fff;
    border: #ffffff31 solid 1px;
  }

  Button {
    display: none;
  }

  .btn-login {
    background-color: #f9ed32;
    color: white;
    border: solid 1px #f9ed32;
    border-radius: 25px;
    border: solid 1px white;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease-out;
    text-decoration: none;
    font-size: 0.5rem;
    text-decoration: none;
  }
  
  .btn-register {
    color: white;
    background-color: #f9ed32;
    border: dotted 1px white;
    border-radius: 25px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease-out;
    text-decoration: none;
    font-size: 1rem;
    text-decoration: none;
  }

  .btn-login:hover {
    background-color: #f9ed32;
    color: black;
    padding: 15px;
    text-decoration: none;
  }
  
  .btn-register.nav-links:hover {
    background: #f9ed32;
    color: black;
    padding: 15px;
  }

  .btns-login-mobile{
    display: flex;
  }
}

.submenu-navbar{
  background: #121212;
  position: absolute;
  top: 100px;
  display: flex;
  flex-direction: column;
}
.container-stepsbuy{
    background-color: #85CDE4;
    padding: 3rem 0 9rem;
    position: relative;
    overflow: hidden
}

.title-stepsbuy{
    color: #000000;
    font-size: 3rem;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
}

.logoTitle_llego_stepsbuy{
    height: 130px;
}

.item-stepsbuy{
    position: relative;
    display: flex;
    font-size: 20px;
    justify-content: center;
    text-align: center;
    background: white;
    font-weight: 800;
    color: #000000;
    padding: 1.3em 1.5em;
    margin: 20px;
}


.number-img-stepsbuy{
    height: 50px;
}

.icon-img-stepsbuy{
    height: 125px;
}

.nubes_stepsbuy{
    z-index: 0;
    position: absolute;
}

#casitaLlego{
    position: absolute;
    z-index: 2;  
}

#linesLlego{
    position: absolute;
    z-index: 3;
}

.hojas_stepsbuy{
    position: absolute;
    z-index: 4;
}



/* Estilos para pantallas de escritorio y laptops */
@media only screen and (min-width: 1024px) {
    .title-stepsbuy{
        font-size: 3rem;
    }

    #number2-stepsbuy{
        margin-top: 75px;
    }

    #number4-stepsbuy{
        margin-top: 75px;
    }

    #nube1{
        top: -5px;
        left: -25px ;
        height: 130px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) 35px 35px 10px)
    }

    #nube2{
        bottom: -80px;
        left: -20px ;
        height: 200px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) 75px -5px 10px)
    }

    #nube3{
        top: 100px;
        right: -390px ;
        height: 230px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) -65px -5px 10px)
    }

    #nube4{
        bottom: 20px;
        right: 10px ;
        height: 375px; 
    }

    #casitaLlego{
        bottom: 80px;
        right: 200px ;
        height: 240px;
        transform: scaleX(-1);
    }

    #linesLlego{
        bottom: 185px;
        right: 400px ;
        height: 80px;
    }

    #hojas1{
        top: -5px;
        left: 38% ;
        height: 5rem; 
    }

    #hojas2{
        top: 50%;
        left: 15px ;
        height: 4rem; 
    }

    #hojas3{
        top: -105px;
        left: 50px ;
        height: 3rem; 
    }

    #hojas4{
        top: -115px;
        left: -30px ;
        height: 3rem; 
    }
}

/* Estilos para tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .title-stepsbuy{
        font-size: 2.2rem;
    }

    #number2-stepsbuy{
        margin-top: 75px;
    }

    #number4-stepsbuy{
        margin-top: 75px;
    }

     
   #nube1{
        top: -55px;
        left: -25px ;
        height: 130px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) 35px 35px 10px)
    }

    #nube2{
        bottom: -100px;
        left: -20px ;
        height: 200px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) 75px -5px 10px)
    }

    #nube3{
        top: 100px;
        right: -390px ;
        height: 230px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) -65px -5px 10px)
    }

    #nube4{
        display: none;
    }


    #casitaLlego{
        bottom: 15px;
        right: 15% ;
        height: 130px;
        transform: scaleX(1);

    }

    #linesLlego{
        bottom: 130px;
        right: 25px ;
        height: 50px;
        transform: rotate(-50deg)  scaleX(-1);
    }

    #hojas1{
        top: -5px;
        left: 38% ;
        height: 5rem; 
    }
#hojas2{
        top: 55%;
        left: 15px ;
        height: 4rem; 
    }
 
    #hojas3{
        top: -105px;
        left: 50px ;
        height: 3rem; 
    }

    #hojas4{
        top: -115px;
        left: -30px ;
        height: 3rem; 
    }
}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
    .title-stepsbuy{
        font-size: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 5px;
        gap: 5px;
        margin-top: 20px;
    }

    #number3-stepsbuy{
        margin-top: 15px;
    }

    #number2-stepsbuy{
        margin-top: 65px;
    }

    #number4-stepsbuy{
        margin-top: 85px;
    }

    
    #nube1{
        top: -75px;
        left: -25px ;
        height: 130px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) 35px 35px 10px)
    }

    #nube2{
        bottom: -100px;
        left: -20px ;
        height: 200px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) 75px -5px 10px)
    }

    #nube3{
        top: 100px;
        right: -390px ;
        height: 230px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) -65px -5px 10px)
    }

    #nube4{
        display: none;
    }


    #casitaLlego{
        bottom: 15px;
        right: 35% ;
        height: 130px;
        transform: scaleX(1);

    }

    #linesLlego{
        bottom: 130px;
        right: 25px ;
        height: 50px;
        transform: rotate(-50deg)  scaleX(-1);
    }

    #hojas1{
        top: -5px;
        left: 38% ;
        height: 5rem; 
    }

 
    #hojas3{
        top: -105px;
        left: 50px ;
        height: 3rem; 
    }

    #hojas4{
        top: -115px;
        left: -30px ;
        height: 3rem; 
    }
    
}
#section-home{
   display: flex;
    background-color: black;
    justify-content: space-between;
    height: 50vw;
    position: relative;
    color: white;
    overflow: hidden;
    flex-direction: row;
}

.section-home-titles{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
}

.section-home-logo{ 
    width: 20rem;
}

.section-home-logo-title{
    font-size: 3rem;
}

.section-home-img-bird{
    width: 100%;
    position: relative;
}


#nube1--section-home{
    position: absolute;
    top: -60px;
    left: -150px;
    width: 10rem;
}

#nube2--section-home{
    position: absolute;
    bottom: -60px;
    left: -200px;
    width: 12rem;
}

#nube3--section-home{
    position: absolute;
    top: 10%;
    right: 0px;
    width: 18rem;
}

#nube4--section-home{
    position: absolute;
    top: 35%;
    left: -3%;
    width: 18rem;
}

#nube5--section-home{
    position: absolute;
    bottom: 10%;
    right: 5%;
    width: 18rem;
}

#llego_pluma1--section-home{
    position: absolute;
    top: 10%;
    right: 40%;
    width: 4rem;
}

#llego_pluma2--section-home{
    position: absolute;
    bottom: 35%;
    left: -15%;
    width: 4rem;
}

#llego_pluma3--section-home{
    position: absolute;
    bottom: 20%;
    right: 5%;
    width: 6rem;
}

#caja1--section-home{
    position: absolute;
    top: 12%;
    left: 3%;
    width: 4rem;
}

#caja2--section-home{
    position: absolute;
    bottom: 25%;
    left: -5%;
    width: 4rem;
}

#caja3--section-home{
    position: absolute;
    top: 30%;
    right: 8%;
    width: 3rem;
}

.socialmedia-section-home{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 7%;
    z-index: 10;
}

.socialmedia-section-home svg:hover{
    cursor: pointer;
    color: #F9ED32;
}

.img-bird-home-page{
    height: 300px;
    position: absolute;
    top: 20%;
    right: -40px;
    transform: scaleX(-1);
    z-index: 1;
}


/* Estilos para pantallas de escritorio y laptops */
@media only screen and (min-width: 1024px) {
   
}

/* Estilos para tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    #section-home{
        display: flex;
         background-color: black;
         justify-content: space-between;
         height: 70vh;
         position: relative;
         color: white;
         overflow: hidden;
         flex-direction: row;
     }
     
     .section-home-titles{
         display: flex;
         align-items: center;
         justify-content: center;
         width: 100%;
         margin-top: 50px;
         z-index: 3;
     }
     
     .section-home-logo{ 
         width: 20rem;
     }
     
     .section-home-logo-title{
         font-size: 3rem;
     }
     
     .section-home-img-bird{
         width: 100%;
         position: relative;
         z-index: 2;
     }
     
     
     #nube1--section-home{
         position: absolute;
         top: -60px;
         left: -150px;
         width: 10rem;
     }
     
     #nube2--section-home{
         position: absolute;
         bottom: -60px;
         left: -200px;
         width: 12rem;
     }
     
     #nube3--section-home{
         position: absolute;
         top: 10%;
         right: -30%;
         width: 18rem;
     }
     
     #nube4--section-home{
         position: absolute;
         top: 35%;
         left: -3%;
         width: 18rem;
     }
     
     #nube5--section-home{
         position: absolute;
         bottom: 10%;
         right: 5%;
         width: 18rem;
     }
     
     #llego_pluma1--section-home{
         position: absolute;
         top: 10%;
         right: 40%;
         width: 4rem;
     }
     
     #llego_pluma2--section-home{
         position: absolute;
         bottom: 35%;
         left: -15%;
         width: 4rem;
     }
     
     #llego_pluma3--section-home{
         position: absolute;
         bottom: 20%;
         right: 5%;
         width: 6rem;
     }
     
     #caja1--section-home{
         position: absolute;
         top: 12%;
         left: 3%;
         width: 4rem;
     }
     
     #caja2--section-home{
         position: absolute;
         bottom: 25%;
         left: -5%;
         width: 4rem;
     }
     
     #caja3--section-home{
         position: absolute;
         top: 30%;
         right: 3%;
         width: 3rem;
     }
     
     .socialmedia-section-home{
         display: flex;
         justify-content: center;
         align-items: center;
         grid-gap: 10px;
         gap: 10px;
         margin-top: 7%;
     }
     
     .socialmedia-section-home svg:hover{
         cursor: pointer;
         color: #F9ED32;
     }
     
     .img-bird-home-page{
         height: 200px;
         position: absolute;
         top: 20%;
         right: -40px;
         transform: scaleX(-1);
         z-index: 1;
     }
     
     
    
}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
    #section-home{
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .section-home-logo{
        width: 15rem;
    }

    .section-home-logo-title{
        font-size: 2rem;
    }

    .section-home-img-bird{
        position: absolute;
        z-index: 1;
        height: 100%;
    }

    .section-home-titles{
        height: 100%;
        z-index: 2;
    }

    .img-bird-home-page {
        height: 70px;
        position: absolute;
        top: 20%;
        right: 35%;
        transform: scaleX(-1);
    }

    #nube3--section-home {
        position: absolute;
        top: 12%;
        right: -65px;
        width: 10rem;
    }

    #nube4--section-home {
       display: none;
    }

    #nube5--section-home {
        position: absolute;
        bottom: -5%;
        right: -20%;
        width: 18rem;
    }

    #llego_pluma1--section-home {
       display: none;
    }

    #llego_pluma3--section-home {
        position: absolute;
        bottom: 20%;
        right: -10%;
        width: 6rem;
    }

    #caja1--section-home {
        display: none;
    }

    #caja2--section-home {
        position: absolute;
        bottom: 35%;
        left: -5%;
        width: 4rem;
    }

    #caja3--section-home{
        position: absolute;
        top: 30%;
        right: 3%;
        width: 3rem;
    }

}

/* BACKGROUND ANIMATED */


.area{
    /* background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);   */
    background: transparent;
    width: 100%;
    height:100vh;
    position: absolute;
    
   
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
.banner-container {
    background: #000;
    position: relative;
    width: 100%;
    padding: 1em 6em;
    text-align: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  

  @keyframes floatAnimation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .floating-element {
    animation: floatAnimation 3s ease-in-out infinite;
  }

  
  .floating-element-slow {
    animation: floatAnimation 5s ease-in-out infinite;
  }
.container-stepsplans{
    background-color: #706EDB;
    padding: 3rem 0;
    position: relative;
    overflow: hidden
}

.title-stepsplans{
    color: white;
    font-size: 2.5rem;
    font-weight: bolder;
    text-align: center;
    padding: 0 200px;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 50;
    filter: drop-shadow(8px 4px 4px rgb(66, 64, 153));
}

.logoTitle_llego_stepsbuy{
    height: 130px;
}

.item-stepsbuy{
    position: relative;
    display: flex;
    font-size: 20px;
    justify-content: center;
    text-align: center;
    background: white;
    font-weight: 800;
    color: #000000;
    padding: 1.3em 1.5em;
    margin: 20px;
}


.number-img-stepsbuy{
    height: 50px;
}

.icon-img-stepsbuy{
    height: 125px;
}

.nubes_stepsbuy{
    z-index: 0;
    position: absolute;
    filter: blur(5px)
}

#casitaLlego{
    position: absolute;
    z-index: 2;  
}

#linesLlego{
    position: absolute;
    z-index: 3;
}

.hojas_stepsbuy{
    position: absolute;
    z-index: 4;
}

.box-plan{
    background-color: #000000;
    align-items: center;
    width: 25rem ;
    padding: 2rem;
    border: 4px solid white;
    box-shadow: inset;
    color: white;
    margin: 0 25px;
    font-size: 14px;
    position: relative;
}

.container-box-plan{
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 3rem;

}
  
  .read-more-target--off {
    display: none;
    opacity: 0;
    max-height: 0;
    font-size: 0;
    transition: .25s ease;
  }

  .read-more-target--on {
    display: block;
    opacity: 1;
    max-height: 0;
    font-size: 0;
    transition: .25s ease;
  }
  
  .read-more-trigger {
    cursor: pointer;
    display: inline-block;
    padding: 0 .5em;
    color: #666;
    font-size: .9em;
    line-height: 2;
    border: 1px solid #ddd;
    border-radius: .25em;
  }

  #hoja2_box_plan{
    bottom: 80px;
    right: -200px;
    height: 3rem;
  }

  #hoja3_box_plan{
    top: 80px;
    left: -150px;
    height: 3rem;
  }

  #hoja4_box_plan{
    top: 230px;
    left: -270px;
    height: 3rem;
  }

  #hoja5_box_plan{
    top: 280px;
    left: -120px;
    height: 4rem;
  }

/* Estilos para pantallas de escritorio y laptops */
@media only screen and (min-width: 1024px) {
   

    #number2-stepsbuy{
        margin-top: 75px;
    }

    #number4-stepsbuy{
        margin-top: 75px;
        margin-bottom: 75px;
    }

    #nube1_stepsplans{
        top: -80px;
        left: -400px ;
        height: 400px; 
            }

    #nube2_stepsplans{
        bottom: -80px;
        left: -20px ;
        height: 200px; 
       }

    #nube3_stepsplans{
        top: 100px;
        right: -390px ;
        height: 230px; 
    }

    #nube4_stepsplans{
        bottom: 20px;
        right: 10px ;
        height: 375px;     
    }

    #hoja_box_plan{
        top: 120px;
        right: -60px;
        height: 4rem;
      }
}

/* Estilos para tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .title-stepsplans{
        font-size: 1.7rem;
    }
     
   #nube1_stepsplans{
        top: -55px;
        left: -25px ;
        height: 130px;
    }

    #nube2_stepsplans{
        bottom: -100px;
        left: -20px ;
        height: 200px; 
        
    }

    #nube3_stepsplans{
        top: 100px;
        right: -390px ;
        height: 230px; 
    }

    #nube4_stepsplans{
        display: none;
    }



    #hoja_box_plan{
        top: 120px;
        right: -60px;
        height: 4rem;
      }
}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
    .title-stepsplans{
        font-size:1.5rem;
        padding: 0 20px;
    }
    
    #nube1_stepsplans{
        top: -45px;
        left: -35px ;
        height: 130px;
    }

    #nube2_stepsplans{
        bottom: -100px;
        left: -20px ;
        height: 200px; 
    }

    #nube3_stepsplans{
        top: 150px;
        right: -500px ;
        height: 230px; 
    }

    #nube4_stepsplans{
        display: none;
    }

    #hoja_box_plan{
        top: 120px;
        right: -30px;
        height: 3rem;
      }

      #number4-stepsbuy{
        margin-top: 75px;
        margin-bottom: 95px;
    }
    
}
.container-stepsLocations {
    background-color: #85CDE4;
    padding: 2rem 4rem ;
    position: relative;
    overflow: hidden;
}

.title-stepsLocations {
    color: black;
    font-size: 5.5rem;
    font-weight: bolder;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 50;
    filter: drop-shadow(8px 4px 4px rgb(133, 205, 228, 0.5));
}

.content-section {
    padding: 0 2rem;
    position: relative;
    z-index: 10;
    margin-top: 0;
}

.text-section p {
    color: black;
    font-size: 1.875rem; /* 30px */
}

.btn-black-llego {
    font-size: 1.5625rem; /* 25px */
}

.highlight {
    color: #7074DC;
}

.nubes_stepsbuy {
    position: absolute;
}

#nube1_stepsplans {
    top: -80px;
    left: -400px;
    height: 400px;
}

#nube2_stepsplans {
    bottom: -80px;
    left: -20px;
    height: 200px;
}

#nube3_stepsplans {
    top: 100px;
    right: -390px;
    height: 230px;
}

.locker-image {
    max-width: 100%;
    height: 500px;
}



#hoja_box_locations{
    top: 40%;
    right: 45%;
    height: 3rem;
  }

#hoja2_box_locations{
    top: 65%;
    right: 55%;
    height: 4.5rem;
  }

  #box1_locations{
    top: 55%;
    right: 45%;
    height: 5rem;
  }

  #hoja3_box_locations{
    top: 65%;
    left: -15px;
    height: 3rem;
  }

  #box2_locations{
    top: 72%;
    right: 2%;
    height: 3rem;
  }

  #hoja4_box_locations{
    top: 85%;
    left: 1%;
    height: 3rem;
    z-index: 5;
  }

  .hojas_locations{
    position: absolute;
}

#btn-locations-llego{
  font-size:40px;
  padding:0 10px;
  border-radius:16px
}

/* Estilos para pantallas de tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .title-stepsLocations {
        font-size: 2.5rem;
    }

    #nube1_stepsplans {
        top: -55px;
        left: -25px;
        height: 130px;
    }

    #nube2_stepsplans {
        bottom: -100px;
        left: -20px;
        height: 200px;
    }

    #nube3_stepsplans {
        top: 100px;
        right: -390px;
        height: 230px;
    }

     /* HOJAS Y CAJAS ABSOLUTE */
     #hoja_box_locations{
        top: 5%;
        right: 5%;
        height: 3rem;
      }
    
    #hoja2_box_locations{
        top: 35%;
        left: -5%;
        height: 3rem;
      }
    
      #box1_locations{
        top: 5%;
        left: 2%;
        height: 4rem;
      }
    
      #hoja3_box_locations{
        display: none;
      }
    
      #box2_locations{
        top: 50%;
        right: 75px;
        height: 3rem;
      }
    
      #hoja4_box_locations{
       display: none;
      }

      #btn-locations-llego{
        font-size:25px;
        padding:10px 10px;
        border-radius:16px;
        margin-bottom: 25px
      }

      .locker-image{
        height: auto;
      }
}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
    .title-stepsLocations {
        font-size: 2.5rem;
        padding: 0 20px;
        margin-bottom: 25px;
    }

    .content-section {
        padding: 0 1rem;
    }

    .text-section p {
        font-size: 1.25rem; /* 20px */
    }

    .btn-black-llego {
        font-size: 1.25rem; /* 20px */
    }

    #nube1_stepsplans {
        top: -45px;
        left: -35px;
        height: 130px;
    }

    #nube2_stepsplans {
        bottom: -100px;
        left: -20px;
        height: 200px;
    }

    #nube3_stepsplans {
        top: 150px;
        right: -500px;
        height: 230px;
    }

    /* HOJAS Y CAJAS ABSOLUTE */
    #hoja_box_locations{
        top: 5%;
        right: 5%;
        height: 3rem;
      }
    
    #hoja2_box_locations{
        top: 35%;
        left: -5%;
        height: 3rem;
      }
    
      #box1_locations{
        top: 5%;
        left: 2%;
        height: 3rem;
      }
    
      #hoja3_box_locations{
        display: none;
      }
    
      #box2_locations{
        top: 55%;
        right: 3%;
        height: 3rem;
      }
    
      #hoja4_box_locations{
       display: none;
      }
    
      #btn-locations-llego{
        font-size:25px;
        padding:10px 10px;
        border-radius:16px;
        margin-bottom: 25px
      }

      .locker-image{
        height: auto;
      }
    
}

.locations-container{
    position: relative;
    padding: 20px;
    z-index: 10;
    overflow: hidden;
}

.cont-locations-filters{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    grid-gap: 20px;
    gap: 20px;
}

.search-filter-locations{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    color: white;
box-sizing: border-box;
padding: 10px;
background: #85CDE4;
border: 1px solid #FFFFFF;
border-radius: 45px;
 
}

.input-filter-locations {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    width: 100%;
    outline: none; /* para eliminar el borde de enfoque predeterminado */
}

/* Estilo para el placeholder */
.input-filter-locations::placeholder {
    color: white;
}

/* Estilo para el borde transparente al hacer clic (enfoque) */
.input-filter-locations:focus {
    border: 2px solid transparent;
}


.btn-close-filter-locations {
    background: none;
    border: 1px solid white;
    color: white;
    font-size: 16px;
    cursor: pointer;
padding: 10px;
background: #85CDE4;
border: 1px solid #FFFFFF;
border-radius: 45px;
font-weight: 600;
z-index: 10;
}

.btn-close-filter-locations:hover{
    background: #FFFFFF;
    color: #85CDE4;
}

.pagination-filter-locations{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.text-pagination-filter{
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4.9px #0080A9;
    
z-index: 10;
}

.btn-pagination-filter-locations{
box-sizing: border-box;
width: 35px;
height: 35px;
display: flex;
justify-content: center;
align-items: center;
background: #FFFFFF;
border: 1px solid #FFFFFF;
box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
border-radius: 50%;
font-size: 18px;
color: #85CDE4;
font-weight: 700;
z-index: 10;
}

.btn-pagination-filter-locations:hover{
    background: #85CDE4;
    color: #FFFFFF;
    cursor: pointer;
}

.location-item{
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 35px;
padding: 20px;
display: flex;
grid-gap: 15px;
gap: 15px;
height: 100%;
z-index: 4;
position: relative;
}

.location-item-title{
font-weight: 700;
font-size: 16px;
line-height: 24px;
color: #000000;
}

.location-item-img{
    box-sizing: border-box;

    height: 150px;
    
    background: #FFFFFF;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

.location-item-header{
    display: flex;
    flex-direction: column;
        justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
}



a:hover{
  text-decoration: none;
}

footer{
  background: black;
  color: white;
  padding: 40px 10%;
}
.footer-ul{
  list-style-type: circle; 
}

.footer-ul a li{
  font-size: medium;
  color: #b1b1b1;
  margin-bottom: 12px;
}

.footer-items-li{
  font-size: medium;
  color: #b1b1b1;
  margin-bottom: 12px;
}

.footer-items-li:hover{
  cursor: pointer;
  color: #f9ed32;
}

.footer-ul a li:hover{
  cursor: pointer;
  color: #f9ed32;
}

.social-media svg{
  color: #b1b1b1;
}

.social-media svg:hover{
  cursor: pointer;
  color: #f9ed32;;
}

hr{
  background-color: #b1b1b17c;
}

.titles-footer{
  color: #f9ed32;
}
#section-aboutUs{
    
    overflow: hidden;
}

.container-content-aboutUs{
    background: #706EDB;
    color: white;
    margin: 0 10%;
    padding: 20px 60px;
    font-size: 25px;
    align-content: center;
    font-weight: bold;
    position: relative;
    top: -50px;
    border-radius: 15px;
}

.hojas_aboutUs{
    position: absolute;
    z-index: 4;
}

#hoja1_about_us{
    top: 0;
    right: -50px;
}

#hoja2_about_us{
    height: 3rem;
    bottom: -30px;
    left: 75px;
}

.nubes_aboutUs{
    position: absolute;
    z-index: 4;
}

#nube1_aboutUs{
    height: 5rem;
    top: 0;
    left: 75px;
}

#nube2_aboutUs{
    height: 5rem;
    bottom: 25px;
    left: 10px;
}

#nube3_aboutUs{
    height: 6rem;
    bottom: 70px;
    right: -75px;
}

/* Estilos para pantallas de escritorio y laptops */
@media only screen and (min-width: 1024px) {
   
}

/* Estilos para tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
   .container-content-aboutUs{
        margin: 0 5%;
        padding: 20px 30px;
        font-size: 20px;
   }

   #hoja1_about_us{
    right: -20px;
    height: 2rem;
   }

   #hoja2_about_us{
    height: 2rem;
}

#nube1_aboutUs{
    height: 5rem;
    top: -30px;
    left: -55px;
}

#nube2_aboutUs{
    height: 5rem;
    bottom: 0px;
    left: -100px;
}

#nube3_aboutUs{
    height: 6rem;
    bottom: 100px;
    right: -200px;
}
}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
    .container-content-aboutUs{
        margin: 0 5%;
        padding: 20px 30px;
        font-size: 15px;
   }

   #hoja1_about_us{
    right: -20px;
    height: 2rem; }

    #hoja2_about_us{
        height: 2rem;
    }

    #nube1_aboutUs{
        height: 5rem;
        top: -30px;
        left: -55px;
    }
    
    #nube2_aboutUs{
        height: 5rem;
        bottom: 0px;
        left: -100px;
    }
    
    #nube3_aboutUs{
        height: 6rem;
        bottom: 100px;
        right: -200px;
    }
}
#section-benefits{
    background-color: #F9ED32;
    padding: 20px 0;
    overflow: hidden;
}

.title-benefits{
    color: #000000;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    position: relative;
}


.logoTitle_llego_benefits{
    height: 130px;
}

.container-list-benefits{
    padding: 0 10%;
}

.cont-item-benefits{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    grid-gap: 20px;
    gap: 20px;
    padding: 25px;
    margin-bottom: 20px;
    text-align: center;
}

.cont-item-benefits img{
    height: 12rem;
    z-index: 5;
}

.hoja_llego_benefits{
    display: flex;
}

#hoja1_benefits{
    height: 3rem;
    margin-left: 25px;
}

#hoja2_benefits{
    height: 2rem;
    transform: scaleX(-1);
    margin-top: 15px;
}

#hoja3_benefits{
    height: 4rem;
    margin-left: 5px;
}

#hojasA_benefits{
    position: absolute;
    top: 45px;
    left: 170px;
}


#hojasB_benefits{
    position: absolute;
    top: -45px;
    right: -50px;
    transform: rotate(-180deg);
}

#nube1_benefits{
    position: absolute;
    top: 65px;
    left: 60px;
    height: 100px;
    z-index: 1;
}

#nube2_benefits{
    position: absolute;
    bottom: 65px;
    left: 60px;
    height: 65px;
    z-index: 1;
}

#nube3_benefits{
    position: absolute;
    top: 65px;
    right: 60px;
    height: 65px;
    z-index: 1;
}

/* Estilos para pantallas de escritorio y laptops */
@media only screen and (min-width: 1024px) {
    .title-benefits{
        font-size: 3.5rem;
        display: flex;
        align-items: center;
    }

    .benefits-titles-section{
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 20px;
        gap: 20px;
    
    }
}

.title-text-benefits{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

/* Estilos para tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .title-benefits{
        font-size: 5rem;
    }

    .title-text-benefits{
        font-size: 3.0rem;
    }

    
    .logoTitle_llego_benefits{
       height: auto;
    }

    .cont-item-benefits img{
        height: 10rem;
        position: relative
    }

    .cont-item-benefits{
        font-size: 16px;
    }

    #hojasA_benefits{
        top: 45px;
        left: 0px;
    }

    #nube1_benefits{
        top: 65px;
        left: 60px;
        height: 50px;
    }
    
    #nube2_benefits{
        bottom: 65px;
        left: 60px;
        height: 50px;
    }
    
    #nube3_benefits{
        top: 65px;
        right: 60px;
        height: 50px;
    }

    .logoTitle_llego_benefits{
        height: 130px;
    }
    
    
}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
    .title-benefits{
        font-size: 2.5rem;
    }



    .title-text-benefits{
        display: flex;
        align-items: center;
        justify-content: center;
       flex-direction: column;
       font-size: 2.5rem;
    }

    .benefits-titles-section {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
}

    .logoTitle_llego_benefits{
        margin-top: 0;
        height:  50px;
    }

    .cont-item-benefits img{
        height: 8rem;
    }
    
    .cont-item-benefits{
        font-size: 16px;
    }

    #hojasA_benefits{
        top: -15px;
        left: -45px;
    }

    #nube1_benefits{
        top: 65px;
        left: 60px;
        height: 50px;
    }
    
    #nube2_benefits{
        bottom: 65px;
        left: 60px;
        height: 50px;
    }
    
    #nube3_benefits{
        top: 65px;
        right: 60px;
        height: 50px;
    }

    .logoTitle_llego_benefits{
        height: 130px;
    }
}
/* -- MAIN CONTENT -- */
main {
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;
   align-items: center;
   height: auto;
   padding-top: 50px;
   padding-bottom: 70px;
}

.main-img {
   width: 50%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: start;
}

.main-img img {
   width: 400px;
}

.maint-content {
   width: 50%;
   height: 100%;
   display: flex;
   justify-content: start;
   align-items: start;
   font-size: 40px;
}

.maint-content-text {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: end;
   font-size: 40px;
   color: #895298;
}

.maint-content-button {
   display: flex;
   justify-content: space-between;
   justify-items: center;
   margin-top: 40px;
   width: 100%;
   font-size: 24px;
}

.main-btn {
   width: 100%;
   padding: 10px;
   margin: 10px;
   border-radius: 50px;
   display: flex;
   justify-content: center;
}

.main-btn.primary {
   background-color: #895298;
   color: white;
}

.main-btn.alternative {
   color: #895298;
   border: 2px solid #895298;
}

/* -- OBJECTIVES --*/
.section-objectives {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   /* 3 columnas en pantallas grandes */
   grid-gap: 30px;
   gap: 30px;
   padding: 0 10% 5px;
   position: relative;
   top: -65px;
}


.item-objective {
   background-color: #895298;
   color: white;
   padding: 20px 50px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   border-radius: 20px;
   background: #A765B9;
}

.item-objective h4 {
   margin-top: 15px;
}

.item-objective p {
   font-weight: 200;
   font-size: 14px;
   line-height: 29px;
   text-align: center;
}

/* Entrega tus ventas en el locker elegido, tu cliente recibirá una notificación con una clave única para retirar. */



.section-benefits{
   padding-top: 40px;
   background-color: #F6F3FF;
   color: #6E437A;
}

.section-benefits-title{
   display: flex;
   justify-items: center;
   justify-content: center;
   flex-direction: column; 
   align-items: center;
}

.section-benefits-delivery{
   display: flex;
   justify-content: center;
   padding: 30px 5%;
}

.content-benefits-delivery{
   padding-left: 5%;
}


.icon-benefits{
   margin-right: 30px;
}

.item-benefits{
   display: flex;
   align-items: center;
   font-size: 22px;
   margin-bottom: 25px;
}

.section-benefits-pickup{
   margin-top: 25px;
   display: flex;
   padding: 70px 10% 40px;
   background-color: white;
}

.content-benefits-pickup{
   padding-right: 5%;
}

.section-banner{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.btn-banner{
   border: 2px solid white;
   padding: 25px;
   border-radius: 50px;
   color: white;
   font-size: large;
   margin-top: 15px;
}


.btn-banner:hover{
   background-color: white;
   color: #895298;
   cursor: pointer;
   box-shadow: 0px 4px 11px rgba(242, 242, 242, 0.31);
}

.section-testimonials{
   padding: 3% 5%;
}

@media (max-width: 860px) {

   .section-objectives {
      padding: 30px 10%;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
   }

   main {
      display: flex;
      justify-content: center;
      justify-items: center;
      width: 100%;
      height: auto;
      padding-top: 0;
      padding-bottom: 25px;
      font-size: larger;
   }

   .maint-content {
      display: flex;
      justify-items: center;
      width: 100%;
      justify-content: center;
   }

   .maint-content-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0;

      font-size: 38px;
   }

   .maint-content-text span {
      font-size: 28px;
   }

   .maint-content-button {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      justify-items: center;
      margin-top: 0;
      width: 85%;

   }

   .maint-content-button .main-btn {
      font-size: 20px;
   }

   .main-img {
      display: flex;
      justify-items: center;
      margin-top: 25px;
   }

   .main-img img {
      width: 250px;
   }

   .item-objective {
      padding: 20px;
   }

   .title-benefits{
      font-size: 25px;
      text-align: left;
   }

   .item-benefits{
      font-size: 20px;
   }

   .section-testimonials{
      padding: 6% 5%;
   }

   .section-testimonials .title-benefits{
      margin-bottom: 25px;
      text-align: center;
   }

   .section-banner{
      font-size: 16px;
   }

   .title-banner{
      font-size: 20px;
   }

   .btn-banner{
      font-size: 14px;
      padding: 16px;
   border-radius: 50px;
   margin-top: 16px ;
   }

}

.image-hover {
   transition: transform 0.3s;
 }
 
 .image-hover:hover {
   transform: scale(1.1);
 }
 
 .image-clicked {
   box-shadow: 0 0 0 5px white;
   border-radius: 15px; 
   padding: 10px;
 }

 .image-clicked:hover {
   cursor: pointer
 }
/* styles.css */

.bannerOpen-container {
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 6em 0;
  overflow: hidden;
}

.bannerOpen-relative-container {
  position: relative;
}

.bannerOpen-pajarito {
  position: absolute;
  top: -15px;
  left: 250px;
}

.bannerOpen-pajarito-img {
  height: 50px;
}

.bannerOpen-nube2 {
  position: absolute;
  top: 32px;
  left: 250px;
}

.bannerOpen-nube2-img {
  height: 50px;
}

.bannerOpen-custom-button {
  font-size: 25px;
  background: #f9ed32;
  font-weight: bold;
  color: black;
}

.bannerOpen-casita {
  position: absolute;
  top: -25px;
  right: 230px;
}

.bannerOpen-casita-img {
  height: 95px;
  position: relative;
  z-index: 2;
  transform: scaleX(-1);
}

.bannerOpen-nube1 {
  position: absolute;
  top: -60px;
  right: 150px;
}

.bannerOpen-nube1-img {
  height: 120px;
  position: relative;
  top: 10px;
  z-index: 1;
}

.bannerOpen-custom-button{
  position: relative;
  z-index: 10;
  box-shadow: rgba(29, 29, 29, 0.9) 0px 7px 29px 0px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
}

.bannerOpen-custom-button:hover{
background: rgba(255, 255, 255, 0.1);
border: #f9ed32 1px solid;
}


/* Estilos para pantallas de tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .bannerOpen-pajarito{
    position: absolute;
    top: -15px;
    left: 0px;
}

.bannerOpen-pajarito-img {
  height: 40px;
}

.bannerOpen-nube2 {
  top: 28px;
  left: 0px;
}

.bannerOpen-nube2-img {
  height: 40px;
}

.bannerOpen-casita {
  top: -25px;
  right: 0;
}

.bannerOpen-casita-img {
  height: 75px;
}

.bannerOpen-nube1 {
  top: -60px;
  right: -35px;
}

.bannerOpen-nube1-img {
  height: 65px;
}

}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
  .bannerOpen-pajarito{
      position: absolute;
      top: -15px;
      left: 0px;
  }

  .bannerOpen-pajarito-img {
    height: 40px;
  }

  .bannerOpen-nube2 {
    top: 28px;
    left: 0px;
  }

  .bannerOpen-nube2-img {
    height: 40px;
  }

  .bannerOpen-casita {
    top: -25px;
    right: 0;
  }

  .bannerOpen-casita-img {
    height: 75px;
  }

  .bannerOpen-nube1 {
    top: -60px;
    right: -35px;
  }

  .bannerOpen-nube1-img {
    height: 75px;
  }



}

.card-pricing{
   background-color: white;
   padding: 35px;   
   /* border: 1px solid gray; */
   border-radius: 35px;
  box-shadow: 0px 4px 11px rgba(221, 221, 221, 0.639);
  filter: drop-shadow(0px 2px 2px rgba(96, 2, 246, 0.296));
}

.img-pricing{
   border-radius: 35px;
}

.tittle-pricing{
   color: rgb(82, 82, 82);
}

.description-pricing{
   color: rgb(149, 149, 149);
   font-size: medium;
   margin-bottom: 15px;
}

.section-pricing{
margin: 50px 20%;
}

.list-descriptions{
   list-style: none;
   padding: 0;
}

.btn-pricing {
   margin-top: 20px;
   color: white;
   background-color: #935CA2;
   border: none;
   border-radius: 25px;
   padding: 0.5rem 1rem;
   cursor: pointer;
   transition: all 0.2s ease-out;
   text-decoration: none;
   font-size: 1rem;
   width: 100%;
 }

 .sect-details{
   padding-left: 40px;
 }

 .icon-check-1{
   color: #935CA2;
   font-size: 20px;
   font-weight: bold;
 }

 .icon-check-2{
   color: #AE2CD2;
   font-size: 20px;
   font-weight: bold;
 }

 .tittle-pricing{
   color: #935CA2;
 }

 @media (max-width: 860px) {
  .section-pricing{
    margin: 30px 10%;
    }

    .sect-details{
      padding-left: 0;
    }

    .tittle-pricing{
      padding-top: 25px;
      text-align: center;
   }
  
   .cost-pricing{
    text-align: center;
    justify-content: center;
   }

   .img-pricing{
    width: 100%;
    height: 150px;
   }

   .btn-pricing {
    display: block;
  }
}
/* NAVBAR
-------------------------------------------------- */
.nav {
    background: #242424;
    height: 60px;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 650ms;
    padding-right: 15px;
    
}
.nav-close {
    background: #242424;
    height: 60px;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 100px;
    width: calc(100% - 100px);
    transition: 650ms;
}

.navMenu {
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.bars-div {
    color: #F9ED32;
    border: 1px solid #F9ED32;
    
    /* background-color: #F9ED32; */
    margin: 8px;
    padding: 8px;
    border-radius: 10px;
}
.bars-div:hover {
    color: #000000;
    border-color: #F9ED32;
    background-color: #F9ED32;
    cursor: pointer;
}.bars-div:active {
    color: #424996;
    border-color: #424996;
    background-color: #a1a4bf;
}

.user {
    margin-left: 18px;
    margin-top: 17px;
    margin-right: 10px;
}
.username {
    font-size: 1.2em;
    text-decoration: none;
    margin-top: 1.1em;
    margin-right: 0.5em;
}
.menu-arrow {
    margin-top: 1.6em;
    margin-right: 1.7em;
}
.user-section {
    font-size: 14px;
    width: auto;
    color: #fff;
    cursor: pointer;
    position: relative;
}
.user-section:hover {
    background-color: #5b629b;
}
.user-section:focus {
    background-color: #363c7a;
}

.active>.dropdown-user {
    height: 84px;
    transition: 650ms;
}

.dropdown-user {
    font-size: 14px;
    position: absolute;
    height: 0;
    top: 60px;
    right: 0;
    min-width: 170px;
    text-align: center;
    background-color: #5b629b;
    overflow: hidden;
    transition: 650ms;
}

.user-option-text {
    display: block;
    padding: 10px;
}
.user-option-text, .user-option-text:hover {
    color: #fff;
    text-decoration: none;
}
.nav-option:hover {
    background: #4d5383;
}

.logo-princ-navbar{
    display: none;
}

@media screen and (max-width: 600px) {
    .name-user-navbar{
        display: none;
    }

    .nav {
        background: #000000;
        height: 60px;
        z-index: 10;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0.5;
        transition: 650ms;
    }
    .nav-close {
        background: #000000;
        height: 60px;
        z-index: 10;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        transition: 650ms;
    }

    .navMenu, .navBtn {
        display: none;
    }

    .navLink {
        color: #fff;
        font-size: 1.1em;
    }

    .bars:active {
        color: #eed4cd;
    }

    .username {
        margin-top: 1em;
    }

    .logo-princ-navbar{
        display: block;
    }
}
.sidebar-open {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: #000000;
    min-width: 80px;
    max-width: 80px;
    height: 100vh;
    transition: 650ms;
    z-index: 11;
}
.sidebar-close {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: #000000;
    min-width: 80px;
    max-width: 80px;
    height: 100vh;
    transition: 650ms;
}

/* SECCION DEL LOGO */
.logo-section {
    height: 7em;
    border-bottom: 1px solid #636363;
}

.logo-boxit {
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
    transition: 650ms;
}

.close-icon {
    color: #fff;
    font-size: 2rem;
}

.close-icon:hover {
    color: rgb(249, 237, 50);
    font-size: 2rem;
    cursor: pointer;
}


/* SECCION DEL USUARIO */
.usersection {
    margin: 0;
    padding: 0;
    display: block;
    align-content: center;
    height: auto;
    border-bottom: 1px solid #636363;
    transition: 650ms;
}

.userdata {
    opacity: 1;
    visibility: visible;
    display: block;
    transition: 650ms;
}
.userdata-close {
    opacity: 0;
    visibility: hidden;
    display: none;
    transition: 650ms;
}

.user-icon {
    margin: auto;
    transition: 650ms;
}
.user-icon:hover {
    color: #E9257D;
    transition: 650ms;
}

/* SECCION DEL LAS FUNCIONES */
.sidebarWrap {
    width: 100%;
    padding: 0;
}

.sidebar-title {
    color: #9b9b9b;
    font-size: 13px;
}
.sidebar-data {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
}

li {
    list-style-type: none;
}

li.relative{
    position: relative;
}

.copyright {
    position: relative;
    bottom: 0;
}


@media only screen and (max-width: 600px) {
    .sidebar-open {
      background: #000000;
      min-width: 200px;
      max-width: 200px;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      transition: 650ms;
      z-index: 100;
    }
    .sidebar-close {
      background: #303141;
      min-width: 100px;
      max-width: 100px;
      height: 100vh;
      position: fixed;
      top: 0;
      left: -100%;
      transition: 650ms;
      z-index: 100;
    }
}
.sidebarLink {
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    list-style: none;
    text-decoration: none;
    font-size: 14px;
    
    display: flex;
    flex-direction: column;
}

.sidebarLink svg {
    color: #F9ED32;
}

.sidebarLinkContent{
    display: flex;
    flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
}

.sidebarLink:hover {
    color: #fff;
    background: #484848;
    border-right: 4px solid rgb(0, 155, 216);
    text-decoration: none;
    cursor: pointer;
    transition: 650ms;
}
.current-page {
    color: #fff;
    background: #000000;
    border-right: 4px solid #F9ED32;
    text-decoration: none;
    cursor: pointer;
    transition: 650ms;
    color: #F9ED32 ;
}

.sidebarLabel {
    margin-left: 0;
    font-size: 12px;
}
.sidebarLabel-close {
    display: none;
}

.dropdownLink {
    height: 28px;
    padding-left: 2.5rem;    
    border-right: 4px solid #E9257D;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #dfdfdf;
    font-size: 10px;
    background: #7a1342;
    transition: 650ms;
}
.dropdownLink:hover {
    text-decoration: none;
    padding-left: 3rem;
    color: #dfdfdf;
    cursor: pointer;
    background: #490c27;
}

ul {
    padding: 0;
}
li {
    list-style-type: none;
}

.submenu-ul {
    position: absolute;
    left: 100%;
    top: 0;
    width: 200px;
    z-index: 10;
}

@media only screen and (max-width: 576px) {
    .sidebarLink {
        font-size: 13px;
        height: 32px;
        padding: 30px;
        display: flex;
        flex-direction: row;
    }
    .dropdownLink {
        font-size: 11px;
        height: 28px;
    }
    .sidebarLink {
        padding-left: 25px;
    }

    .sidebarLinkContent{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        grid-gap: 8px;
        gap: 8px;
        border-bottom: 1px solid rgb(50, 50, 50);
        padding-bottom: 8px;
    }
}
