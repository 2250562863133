.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
  }

  .grid-item {
    background-color: #ffffff;
    text-align: center;
    /* border: 1px solid #ccc; */
    color: rgb(93, 91, 91);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(92, 92, 92, 0.211);
    /* word-wrap: break-word;  */
    word-break: break-word; 
    /* overflow-wrap: break-word; */
  }

  .grid-item:hover{
    box-shadow: 0 0 10px #4756ffa8;
    color: rgb(93, 91, 91);
    cursor: pointer;
  }

  .img-item-canvas{
    display: flex;
    align-items: center;
    background-color: #000000;
    color: #F9ED32;
    border-radius: 8px 0 0 8px;
    padding: 15px;
  }

  .img-item-canvas.fucsia{
    background-color: #e9257d;
    color: #ffffff;
  }

  .description-item-canvas{
    padding: 15px;
    text-align: start;
  }

  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }

  @media (max-width: 480px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
  }


  .clients-container {
    background-color: #ffffff;
    margin: 20px;
    border: 1px solid #bababa;
    box-shadow: 0 0 20px 1px #dfdfdf;
  }
  
  .graph {
    height: 100%;
    min-width: 100%;
  }
  
  .link-hover-effect{
    color: #424996;
    transition: color
  }
  .link-hover-effect:hover {
    color: #4c5bff;
  }
  
  .box-card-general {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;  padding: 25px;
    background-color: white;
    border-radius: 10px;
  }
  
  .details-section-option{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding:  0  25px 0 0;
    border-right: 3px solid #f6f6f6;
    width: 15rem;
    gap: 20px;
  }
  
  .details-section-information{
    padding: 0 25px;
    width: 100%;
  }
  
  .box-card-general-dash{
    margin: 10px 0;
    padding: 25px;
    background-color: white;
    border-radius: 10px;
    border: 1px dashed rgba(70, 93, 99, 0.576);
    width: fit-content;
    display: flex;
      flex-direction: column;
      justify-content: center;
  }
  
  .details-section-option-item.active {
    color: #4c5bff;
    background: #e9f0ff;
    padding: 10px;
    border-radius: 25px;
    font-weight: 500;
    border: none
  }
  
  .details-section-option-item {
    color: #465d6393;
    border-radius: 25px;
    padding: 10px;
    border: 1px dashed rgba(101, 110, 113, 0.287);
    display: flex;
    justify-content: center;
    font-size: 12px;
    gap: 12px;
    width: 100%;
  }
  
  .details-section-option-item:hover {
    color: #4c5bff;
    cursor: pointer;
    border: 1px dashed #4c5bff;
  }
  
  .details-section-option-item.active:hover {
    color: #4c5bff;
    cursor: default;
    border: none;
  }
  
  .details-profile-icon {
              width: 65px;        
              height: 65px;        
              border-radius: 50%;   
              background-color: #424996;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
  }
  
  
  .details-info-response{
    color: #777778 ;
    font-weight: 500;
  }
  
  .details-info-label{
    color: #afb6c3
  }
  
  .details-info{
    margin: 15px 0;
    font-size: 14px;
  }
  
  .badge-status{
    color: #4c5bff;
    background: #e9f0ff;
    padding: 5px 10px;
    border-radius: 25px;
    font-size: 12px;
    width: fit-content;
    font-weight: 500;
    margin-bottom: 0;
    /* box-shadow: rgba(186, 185, 185, 0.2) 0px 7px 10px 0px; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  
  .badge-status.success{
    color: green;
    background: #efffe9;
  }
  
  .badge-status.warning{
    color: rgb(193, 162, 3);
    background: #fffee9;
  }
  
  .badge-status.alert{
    color: rgb(222, 5, 5);
    background: #ffe9e9;
  }
  
  .badge-status.fucsia{
    color: #e9257d;
    background: #ffebf4;
  }
  
  .details-section-general {
    display: flex;
    flex-direction: row;
  }
  
  .header-filter-box{
    display: flex;
    justify-content: space-between;
  }
  
  #details-options-viewactions{
    border: 1px solid #d8d8d8;
    color: #666666;
    border-radius: 8px;
    font-size: 13px;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
  }
  
  #details-options-viewactions:hover{
    background-color: #f5f5f5;
    cursor: pointer;
  }
  
  .panel-active>.dropdown-menu {
    height: auto;
    display: block;
  }
  
  .dropdown-menu {
    position: absolute;
    /* height: 0; */
    right: 0;
    top: 60px;
    background-color: #F5F5F5;
  }
  
  .details-section-info-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
  
  #subtitle-createClient{
    display: block;
  }
  
  /* Estilos para pantallas pequeñas (hasta 600px de ancho) */
  @media (max-width: 600px) {
  
    .details-section-general{
      flex-direction: column;
    }
  
    .details-section-option {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      padding: 0  0 15px 0;
      border-bottom: 3px solid #f6f6f6;
      border-right: 0px;
      width: 100%;
      gap: 15px;
      margin-bottom: 25px;
  }
  
  .details-profile-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  
  .details-section-information{
    padding: 0 0;
    width: 100%;
  }
  
  .header-filter-box{
    display: flex;
   flex-direction: column;
  }
  
  .details-section-info-group{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  #subtitle-createClient{
    display: none;
  }
  
  
  }
  
  
  /* Estilos para pantallas medianas (de 601px a 1024px de ancho) */
  @media (min-width: 601px) and (max-width: 1024px) {
  
    .details-section-general{
      flex-direction: column;
    }
  
    .details-section-option {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      padding: 0  0 15px 0;
      border-bottom: 3px solid #f6f6f6;
      border-right: 0px;
      width: 100%;
      gap: 15px;
      margin-bottom: 25px;
  }
  
  .details-profile-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  
  .details-section-information{
    padding: 0 0;
    width: 100%;
  }
  
  .header-filter-box{
    display: flex;
   flex-direction: column;
  }
  
  .details-section-info-group{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  }
  
  /* Estilos para pantallas grandes (más de 1024px de ancho) */
  @media (min-width: 1025px) {
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    justify-items: center;
    width: 100%;
    margin: 20px 0;
  }
  
  .pagination-btn {
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px 10px;
    margin: 0 2px;
    cursor: pointer;
  }
  
  .pagination-btn.active {
    background-color: #343A4F;
    color: white;
  }
  
  .pagination-btn:disabled {
    background-color: #f1f1f1;
    cursor: not-allowed;
  }