.container-stepsbuy{
    background-color: #85CDE4;
    padding: 3rem 0 9rem;
    position: relative;
    overflow: hidden
}

.title-stepsbuy{
    color: #000000;
    font-size: 3rem;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.logoTitle_llego_stepsbuy{
    height: 130px;
}

.item-stepsbuy{
    position: relative;
    display: flex;
    font-size: 20px;
    justify-content: center;
    text-align: center;
    background: white;
    font-weight: 800;
    color: #000000;
    padding: 1.3em 1.5em;
    margin: 20px;
}


.number-img-stepsbuy{
    height: 50px;
}

.icon-img-stepsbuy{
    height: 125px;
}

.nubes_stepsbuy{
    z-index: 0;
    position: absolute;
}

#casitaLlego{
    position: absolute;
    z-index: 2;  
}

#linesLlego{
    position: absolute;
    z-index: 3;
}

.hojas_stepsbuy{
    position: absolute;
    z-index: 4;
}



/* Estilos para pantallas de escritorio y laptops */
@media only screen and (min-width: 1024px) {
    .title-stepsbuy{
        font-size: 3rem;
    }

    #number2-stepsbuy{
        margin-top: 75px;
    }

    #number4-stepsbuy{
        margin-top: 75px;
    }

    #nube1{
        top: -5px;
        left: -25px ;
        height: 130px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) 35px 35px 10px)
    }

    #nube2{
        bottom: -80px;
        left: -20px ;
        height: 200px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) 75px -5px 10px)
    }

    #nube3{
        top: 100px;
        right: -390px ;
        height: 230px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) -65px -5px 10px)
    }

    #nube4{
        bottom: 20px;
        right: 10px ;
        height: 375px; 
    }

    #casitaLlego{
        bottom: 80px;
        right: 200px ;
        height: 240px;
        transform: scaleX(-1);
    }

    #linesLlego{
        bottom: 185px;
        right: 400px ;
        height: 80px;
    }

    #hojas1{
        top: -5px;
        left: 38% ;
        height: 5rem; 
    }

    #hojas2{
        top: 50%;
        left: 15px ;
        height: 4rem; 
    }

    #hojas3{
        top: -105px;
        left: 50px ;
        height: 3rem; 
    }

    #hojas4{
        top: -115px;
        left: -30px ;
        height: 3rem; 
    }
}

/* Estilos para tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .title-stepsbuy{
        font-size: 2.2rem;
    }

    #number2-stepsbuy{
        margin-top: 75px;
    }

    #number4-stepsbuy{
        margin-top: 75px;
    }

     
   #nube1{
        top: -55px;
        left: -25px ;
        height: 130px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) 35px 35px 10px)
    }

    #nube2{
        bottom: -100px;
        left: -20px ;
        height: 200px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) 75px -5px 10px)
    }

    #nube3{
        top: 100px;
        right: -390px ;
        height: 230px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) -65px -5px 10px)
    }

    #nube4{
        display: none;
    }


    #casitaLlego{
        bottom: 15px;
        right: 15% ;
        height: 130px;
        transform: scaleX(1);

    }

    #linesLlego{
        bottom: 130px;
        right: 25px ;
        height: 50px;
        transform: rotate(-50deg)  scaleX(-1);
    }

    #hojas1{
        top: -5px;
        left: 38% ;
        height: 5rem; 
    }
#hojas2{
        top: 55%;
        left: 15px ;
        height: 4rem; 
    }
 
    #hojas3{
        top: -105px;
        left: 50px ;
        height: 3rem; 
    }

    #hojas4{
        top: -115px;
        left: -30px ;
        height: 3rem; 
    }
}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
    .title-stepsbuy{
        font-size: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        margin-top: 20px;
    }

    #number3-stepsbuy{
        margin-top: 15px;
    }

    #number2-stepsbuy{
        margin-top: 65px;
    }

    #number4-stepsbuy{
        margin-top: 85px;
    }

    
    #nube1{
        top: -75px;
        left: -25px ;
        height: 130px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) 35px 35px 10px)
    }

    #nube2{
        bottom: -100px;
        left: -20px ;
        height: 200px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) 75px -5px 10px)
    }

    #nube3{
        top: 100px;
        right: -390px ;
        height: 230px; 
        filter: drop-shadow(rgba(255, 255, 255, 0.32) -65px -5px 10px)
    }

    #nube4{
        display: none;
    }


    #casitaLlego{
        bottom: 15px;
        right: 35% ;
        height: 130px;
        transform: scaleX(1);

    }

    #linesLlego{
        bottom: 130px;
        right: 25px ;
        height: 50px;
        transform: rotate(-50deg)  scaleX(-1);
    }

    #hojas1{
        top: -5px;
        left: 38% ;
        height: 5rem; 
    }

 
    #hojas3{
        top: -105px;
        left: 50px ;
        height: 3rem; 
    }

    #hojas4{
        top: -115px;
        left: -30px ;
        height: 3rem; 
    }
    
}