.sidebarLink {
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    list-style: none;
    text-decoration: none;
    font-size: 14px;
    
    display: flex;
    flex-direction: column;
}

.sidebarLink svg {
    color: #F9ED32;
}

.sidebarLinkContent{
    display: flex;
    flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
}

.sidebarLink:hover {
    color: #fff;
    background: #484848;
    border-right: 4px solid rgb(0, 155, 216);
    text-decoration: none;
    cursor: pointer;
    transition: 650ms;
}
.current-page {
    color: #fff;
    background: #000000;
    border-right: 4px solid #F9ED32;
    text-decoration: none;
    cursor: pointer;
    transition: 650ms;
    color: #F9ED32 ;
}

.sidebarLabel {
    margin-left: 0;
    font-size: 12px;
}
.sidebarLabel-close {
    display: none;
}

.dropdownLink {
    height: 28px;
    padding-left: 2.5rem;    
    border-right: 4px solid #E9257D;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #dfdfdf;
    font-size: 10px;
    background: #7a1342;
    transition: 650ms;
}
.dropdownLink:hover {
    text-decoration: none;
    padding-left: 3rem;
    color: #dfdfdf;
    cursor: pointer;
    background: #490c27;
}

ul {
    padding: 0;
}
li {
    list-style-type: none;
}

.submenu-ul {
    position: absolute;
    left: 100%;
    top: 0;
    width: 200px;
    z-index: 10;
}

@media only screen and (max-width: 576px) {
    .sidebarLink {
        font-size: 13px;
        height: 32px;
        padding: 30px;
        display: flex;
        flex-direction: row;
    }
    .dropdownLink {
        font-size: 11px;
        height: 28px;
    }
    .sidebarLink {
        padding-left: 25px;
    }

    .sidebarLinkContent{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 8px;
        border-bottom: 1px solid rgb(50, 50, 50);
        padding-bottom: 8px;
    }
}