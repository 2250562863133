#homeOptions {
    min-height: calc(100vh - 220px);
}

#homeOptions > div {
    border-radius: 1rem;
}

.btnCounterHome {
    font-size: 1.2rem;
    width: 10rem;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
}