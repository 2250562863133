.graph-container {
    height: 200px;
    width: 90%;
}

.card-info {
    height: 50%;
}

.card-info-vertical {
    height: 100%;
}

.charts-container {
    min-height: 200px;
    min-width: 220px;
}