#section-home{
   display: flex;
    background-color: black;
    justify-content: space-between;
    height: 50vw;
    position: relative;
    color: white;
    overflow: hidden;
    flex-direction: row;
}

.section-home-titles{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
}

.section-home-logo{ 
    width: 20rem;
}

.section-home-logo-title{
    font-size: 3rem;
}

.section-home-img-bird{
    width: 100%;
    position: relative;
}


#nube1--section-home{
    position: absolute;
    top: -60px;
    left: -150px;
    width: 10rem;
}

#nube2--section-home{
    position: absolute;
    bottom: -60px;
    left: -200px;
    width: 12rem;
}

#nube3--section-home{
    position: absolute;
    top: 10%;
    right: 0px;
    width: 18rem;
}

#nube4--section-home{
    position: absolute;
    top: 35%;
    left: -3%;
    width: 18rem;
}

#nube5--section-home{
    position: absolute;
    bottom: 10%;
    right: 5%;
    width: 18rem;
}

#llego_pluma1--section-home{
    position: absolute;
    top: 10%;
    right: 40%;
    width: 4rem;
}

#llego_pluma2--section-home{
    position: absolute;
    bottom: 35%;
    left: -15%;
    width: 4rem;
}

#llego_pluma3--section-home{
    position: absolute;
    bottom: 20%;
    right: 5%;
    width: 6rem;
}

#caja1--section-home{
    position: absolute;
    top: 12%;
    left: 3%;
    width: 4rem;
}

#caja2--section-home{
    position: absolute;
    bottom: 25%;
    left: -5%;
    width: 4rem;
}

#caja3--section-home{
    position: absolute;
    top: 30%;
    right: 8%;
    width: 3rem;
}

.socialmedia-section-home{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 7%;
    z-index: 10;
}

.socialmedia-section-home svg:hover{
    cursor: pointer;
    color: #F9ED32;
}

.img-bird-home-page{
    height: 300px;
    position: absolute;
    top: 20%;
    right: -40px;
    transform: scaleX(-1);
    z-index: 1;
}


/* Estilos para pantallas de escritorio y laptops */
@media only screen and (min-width: 1024px) {
   
}

/* Estilos para tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    #section-home{
        display: flex;
         background-color: black;
         justify-content: space-between;
         height: 70vh;
         position: relative;
         color: white;
         overflow: hidden;
         flex-direction: row;
     }
     
     .section-home-titles{
         display: flex;
         align-items: center;
         justify-content: center;
         width: 100%;
         margin-top: 50px;
         z-index: 3;
     }
     
     .section-home-logo{ 
         width: 20rem;
     }
     
     .section-home-logo-title{
         font-size: 3rem;
     }
     
     .section-home-img-bird{
         width: 100%;
         position: relative;
         z-index: 2;
     }
     
     
     #nube1--section-home{
         position: absolute;
         top: -60px;
         left: -150px;
         width: 10rem;
     }
     
     #nube2--section-home{
         position: absolute;
         bottom: -60px;
         left: -200px;
         width: 12rem;
     }
     
     #nube3--section-home{
         position: absolute;
         top: 10%;
         right: -30%;
         width: 18rem;
     }
     
     #nube4--section-home{
         position: absolute;
         top: 35%;
         left: -3%;
         width: 18rem;
     }
     
     #nube5--section-home{
         position: absolute;
         bottom: 10%;
         right: 5%;
         width: 18rem;
     }
     
     #llego_pluma1--section-home{
         position: absolute;
         top: 10%;
         right: 40%;
         width: 4rem;
     }
     
     #llego_pluma2--section-home{
         position: absolute;
         bottom: 35%;
         left: -15%;
         width: 4rem;
     }
     
     #llego_pluma3--section-home{
         position: absolute;
         bottom: 20%;
         right: 5%;
         width: 6rem;
     }
     
     #caja1--section-home{
         position: absolute;
         top: 12%;
         left: 3%;
         width: 4rem;
     }
     
     #caja2--section-home{
         position: absolute;
         bottom: 25%;
         left: -5%;
         width: 4rem;
     }
     
     #caja3--section-home{
         position: absolute;
         top: 30%;
         right: 3%;
         width: 3rem;
     }
     
     .socialmedia-section-home{
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 10px;
         margin-top: 7%;
     }
     
     .socialmedia-section-home svg:hover{
         cursor: pointer;
         color: #F9ED32;
     }
     
     .img-bird-home-page{
         height: 200px;
         position: absolute;
         top: 20%;
         right: -40px;
         transform: scaleX(-1);
         z-index: 1;
     }
     
     
    
}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
    #section-home{
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .section-home-logo{
        width: 15rem;
    }

    .section-home-logo-title{
        font-size: 2rem;
    }

    .section-home-img-bird{
        position: absolute;
        z-index: 1;
        height: 100%;
    }

    .section-home-titles{
        height: 100%;
        z-index: 2;
    }

    .img-bird-home-page {
        height: 70px;
        position: absolute;
        top: 20%;
        right: 35%;
        transform: scaleX(-1);
    }

    #nube3--section-home {
        position: absolute;
        top: 12%;
        right: -65px;
        width: 10rem;
    }

    #nube4--section-home {
       display: none;
    }

    #nube5--section-home {
        position: absolute;
        bottom: -5%;
        right: -20%;
        width: 18rem;
    }

    #llego_pluma1--section-home {
       display: none;
    }

    #llego_pluma3--section-home {
        position: absolute;
        bottom: 20%;
        right: -10%;
        width: 6rem;
    }

    #caja1--section-home {
        display: none;
    }

    #caja2--section-home {
        position: absolute;
        bottom: 35%;
        left: -5%;
        width: 4rem;
    }

    #caja3--section-home{
        position: absolute;
        top: 30%;
        right: 3%;
        width: 3rem;
    }

}

/* BACKGROUND ANIMATED */


.area{
    /* background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);   */
    background: transparent;
    width: 100%;
    height:100vh;
    position: absolute;
    
   
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}