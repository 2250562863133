.locations-container{
    position: relative;
    padding: 20px;
    z-index: 10;
    overflow: hidden;
}

.cont-locations-filters{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
}

.search-filter-locations{
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
box-sizing: border-box;
padding: 10px;
background: #85CDE4;
border: 1px solid #FFFFFF;
border-radius: 45px;
 
}

.input-filter-locations {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    width: 100%;
    outline: none; /* para eliminar el borde de enfoque predeterminado */
}

/* Estilo para el placeholder */
.input-filter-locations::placeholder {
    color: white;
}

/* Estilo para el borde transparente al hacer clic (enfoque) */
.input-filter-locations:focus {
    border: 2px solid transparent;
}


.btn-close-filter-locations {
    background: none;
    border: 1px solid white;
    color: white;
    font-size: 16px;
    cursor: pointer;
padding: 10px;
background: #85CDE4;
border: 1px solid #FFFFFF;
border-radius: 45px;
font-weight: 600;
z-index: 10;
}

.btn-close-filter-locations:hover{
    background: #FFFFFF;
    color: #85CDE4;
}

.pagination-filter-locations{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.text-pagination-filter{
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4.9px #0080A9;
    
z-index: 10;
}

.btn-pagination-filter-locations{
box-sizing: border-box;
width: 35px;
height: 35px;
display: flex;
justify-content: center;
align-items: center;
background: #FFFFFF;
border: 1px solid #FFFFFF;
box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
border-radius: 50%;
font-size: 18px;
color: #85CDE4;
font-weight: 700;
z-index: 10;
}

.btn-pagination-filter-locations:hover{
    background: #85CDE4;
    color: #FFFFFF;
    cursor: pointer;
}

.location-item{
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 35px;
padding: 20px;
display: flex;
gap: 15px;
height: 100%;
z-index: 4;
position: relative;
}

.location-item-title{
font-weight: 700;
font-size: 16px;
line-height: 24px;
color: #000000;
}

.location-item-img{
    box-sizing: border-box;

    height: 150px;
    
    background: #FFFFFF;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

.location-item-header{
    display: flex;
    flex-direction: column;
        justify-content: space-between;
    gap: 10px;
}


