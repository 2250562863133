.forgot-form {
    max-width: 100%;
    width: 600px;
    margin: auto;
    background-color: #eeeeee;
    border-radius: 46px;
    padding: 2em;
}

@media (max-width: 630px) {
  .forgot-form {
    padding-top: 2em;
    margin: 20px;
  }
}

/* CUSTOM BUTTON */
.btn-forgot {
  font-size: 15px;
  font-weight: 600;
  min-height: 40px;
  min-width: 150px;
  margin: 0.5em;
}