.container-stepsplans{
    background-color: #706EDB;
    padding: 3rem 0;
    position: relative;
    overflow: hidden
}

.title-stepsplans{
    color: white;
    font-size: 2.5rem;
    font-weight: bolder;
    text-align: center;
    padding: 0 200px;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 50;
    filter: drop-shadow(8px 4px 4px rgb(66, 64, 153));
}

.logoTitle_llego_stepsbuy{
    height: 130px;
}

.item-stepsbuy{
    position: relative;
    display: flex;
    font-size: 20px;
    justify-content: center;
    text-align: center;
    background: white;
    font-weight: 800;
    color: #000000;
    padding: 1.3em 1.5em;
    margin: 20px;
}


.number-img-stepsbuy{
    height: 50px;
}

.icon-img-stepsbuy{
    height: 125px;
}

.nubes_stepsbuy{
    z-index: 0;
    position: absolute;
    filter: blur(5px)
}

#casitaLlego{
    position: absolute;
    z-index: 2;  
}

#linesLlego{
    position: absolute;
    z-index: 3;
}

.hojas_stepsbuy{
    position: absolute;
    z-index: 4;
}

.box-plan{
    background-color: #000000;
    align-items: center;
    width: 25rem ;
    padding: 2rem;
    border: 4px solid white;
    box-shadow: inset;
    color: white;
    margin: 0 25px;
    font-size: 14px;
    position: relative;
}

.container-box-plan{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 3rem;

}
  
  .read-more-target--off {
    display: none;
    opacity: 0;
    max-height: 0;
    font-size: 0;
    transition: .25s ease;
  }

  .read-more-target--on {
    display: block;
    opacity: 1;
    max-height: 0;
    font-size: 0;
    transition: .25s ease;
  }
  
  .read-more-trigger {
    cursor: pointer;
    display: inline-block;
    padding: 0 .5em;
    color: #666;
    font-size: .9em;
    line-height: 2;
    border: 1px solid #ddd;
    border-radius: .25em;
  }

  #hoja2_box_plan{
    bottom: 80px;
    right: -200px;
    height: 3rem;
  }

  #hoja3_box_plan{
    top: 80px;
    left: -150px;
    height: 3rem;
  }

  #hoja4_box_plan{
    top: 230px;
    left: -270px;
    height: 3rem;
  }

  #hoja5_box_plan{
    top: 280px;
    left: -120px;
    height: 4rem;
  }

/* Estilos para pantallas de escritorio y laptops */
@media only screen and (min-width: 1024px) {
   

    #number2-stepsbuy{
        margin-top: 75px;
    }

    #number4-stepsbuy{
        margin-top: 75px;
        margin-bottom: 75px;
    }

    #nube1_stepsplans{
        top: -80px;
        left: -400px ;
        height: 400px; 
            }

    #nube2_stepsplans{
        bottom: -80px;
        left: -20px ;
        height: 200px; 
       }

    #nube3_stepsplans{
        top: 100px;
        right: -390px ;
        height: 230px; 
    }

    #nube4_stepsplans{
        bottom: 20px;
        right: 10px ;
        height: 375px;     
    }

    #hoja_box_plan{
        top: 120px;
        right: -60px;
        height: 4rem;
      }
}

/* Estilos para tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .title-stepsplans{
        font-size: 1.7rem;
    }
     
   #nube1_stepsplans{
        top: -55px;
        left: -25px ;
        height: 130px;
    }

    #nube2_stepsplans{
        bottom: -100px;
        left: -20px ;
        height: 200px; 
        
    }

    #nube3_stepsplans{
        top: 100px;
        right: -390px ;
        height: 230px; 
    }

    #nube4_stepsplans{
        display: none;
    }



    #hoja_box_plan{
        top: 120px;
        right: -60px;
        height: 4rem;
      }
}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
    .title-stepsplans{
        font-size:1.5rem;
        padding: 0 20px;
    }
    
    #nube1_stepsplans{
        top: -45px;
        left: -35px ;
        height: 130px;
    }

    #nube2_stepsplans{
        bottom: -100px;
        left: -20px ;
        height: 200px; 
    }

    #nube3_stepsplans{
        top: 150px;
        right: -500px ;
        height: 230px; 
    }

    #nube4_stepsplans{
        display: none;
    }

    #hoja_box_plan{
        top: 120px;
        right: -30px;
        height: 3rem;
      }

      #number4-stepsbuy{
        margin-top: 75px;
        margin-bottom: 95px;
    }
    
}