/* CUSTOMIZE THE FORM TO LOGIN
-------------------------------------------------- */
.signin-form {
    max-width: 100%;
    width: 400px;
    margin: auto;
    background-color: white;
    border: 1px solid;
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
    border-radius: 40px;
    padding: 3em;
    border-color: #fdf3ef;
}

@media (max-width: 30em) {
    .signin-form {
      padding: 2.5em;
      margin: 20px;
    }
}