.faqs-container {
  background-color: #F0F5F5;
  margin: 15px;
  padding: 15px;
}

#cover-faqs {
  background-image: linear-gradient(to bottom left, #424996, #303141 80%);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: #fff;
}

.question-container {
  background-color: #fff;
  padding: 25px;
  color: black;
  border-radius: 8px;
  border-bottom: solid 2px #dae1f5;
 
}

.questions {
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  border-radius: 15px;
}
.questions:hover {
  color: #F9ED32;
}

.q-title {
  font-size: 20px;
  font-weight: 200
}

.faqs-content .row {
  padding: 0 10%; 
}

.circle-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.faqs-title{
  color:#6E6DD7;
  font-size: 40px;
}

.question-icon{
  color: #6E6DD7;

}

.btn-pagination{
  color: #c9c9c9;
  font-weight: bold;
  border: 1px solid #c9c9c9;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center
}

.btn-pagination:hover{
  color: #6E6DD7;
  cursor: pointer;
  border: 1px solid #6E6DD7;
}

.pagination-faqs{
  display: flex;
  justify-content: center;
  align-items: center;
gap: 10px;
}

.container-html-faqs ul{
    list-style-type: circle;
    margin-left: 20px;
}

@media (max-width: 768px) { 
  .faqs-content .row {
    padding: 0; 
  }
}