.navbar {
  background: transparent;
  /* background-color: red; */
  width: 100%;
  height: 74px;
  display: flex;
  justify-content: space-around;

  align-content: center;
  font-size: 1.1rem;
  padding: 15px 30px;
  /* border-bottom: 2px solid #d5d5d5a0; */
  position: fixed;
  top: 0;
  z-index: 1000; 
  font-size: 1rem;
}

.navbar-scrolling{
  transition: background-color 0.5s ease; 
  background-color: black;
}

.logo-scrolling {
  width: 100px;
}

.nav-links-scrolling {
  padding: 0.5rem 0; 
}

.none-links{
  text-decoration: none;
}

.btn-login-scrolling, .btn-register-scrolling {
  padding: 0.25rem 0.5rem; 
  font-size: 0.9rem; 
}

.navbar-logo {
  color: #f9ed32;
  justify-self: center;
  margin-left: 20px;
  cursor: pointer;
}

.fa-react {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  grid-template-columns: repeat(4, auto);
  gap: 20px;
  list-style: none;
  text-align: center;
  justify-content: center;
  align-content: center;
  display: flex;
  align-items: center;
  z-index: 999;
}

.nav-links.active {
  color: #f9ed32;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

.nav-links.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 4px;
  background-color: #f9ed32;
  border-radius: 8px;
}


.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #f9ed32;
  color: black;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  text-decoration: none;
}
.nav-links.active{
  color: white;
}

.nav-links.active:hover {
  color: black;
  background-color: #f9ed32;
  padding: 0.5rem 1rem;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  color: #f9ed32;
}


.btn-login {
  background-color: transparent;
  color: #ffffff;
  border: solid 1px transparent;
  border-radius: 25px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease-out;
  text-decoration: none;
  font-size: 1rem;
  text-decoration: none;
}

.btn-register {
  color: #f9ed32;
  background-color: transparent;
  border: solid 1px #f9ed32;
  border-radius: 25px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease-out;
  text-decoration: none;
  font-size: 1rem;
  text-decoration: none;
}

a {
  text-decoration: none;
}

.btns-login-mobile{
  display: none;
}

/* Mobile NAV */
.navbar-mobile{
  background: #121212;
  color: #fff;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: fixed;
  inset-inline-end: 0px;
  top: 0px;
  padding: 40px 16px 16px;
  min-width: 320px;
  max-width: 100vw;
  z-index: 998;
  transition: transform 0.3s ease-out; 
  transform: translateX(100%);
}

.navbar-mobile.active {
  transform: translateX(0); /* Move on-screen */
}

.navbar-mob-background {
  top: 0;
  left: 0;
  position: fixed;
  background: #000000b9;
  width: 100vw;
  height: 100%;
  display: none; 
  opacity: 0;
  transition: opacity 0.3s ease-out; 
  /* Transition for fading effect */
}

.navbar-mob-background.active {
display: block;
opacity: 1;
}



.navbar-mob-content{
  z-index: 980;
}

.navbar-mob-options{
  display: flex;
  flex-direction: column;
  gap: 15px;

}

.navbar-mob-section {
  background-color:#ffffff12;;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
}

.navbar-mob-item {
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
  text-decoration: none;
  color: inherit;  
}

.navbar-mob-item:hover {
  color: #f9ed32;
  cursor: pointer;
}

.navbar-mob-item-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-section-mob{
  background-color: #ffffff12;
  border-radius: 4px;
  display: inline-flex;
  padding: 8px;
}


@media (max-width: 1110px) {

  .navbar {
    justify-content: space-between;
  }

  .nav-menu {
    display: none
  }

  .nav-menu.active {
    border-radius: 0 0 16px 16px;
    padding: 25px;
    margin-top: 20px;
    height: auto;
    background: #f9ed32;
    left: 0;
    top: 80px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 3px;
    width: 100%;
    display: table;
    color: white;
    font-weight: 200;
    transition: background-color 0.5s, color 0.5s, border-radius 0.5s, padding 0.5s;
  }

  .nav-links:hover {
    background-color: white;
    color: #f9ed32;
    border-radius: 50px;
    padding: 15px;
  }

  .nav-links.active {
    color: white;
    padding: 0.5rem 1rem;
  }

  .menu-icon {
    z-index: 999;
    display: block;
    position: absolute;
    top: -15px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1rem;
    border-radius: 50px;
    background: white;
    color: #f9ed32;
    text-decoration: none;
    font-size: 1rem;
    margin: 2px;
  }

  .nav-links-mobile-alt {
    display: block;
    text-align: center;
    padding: 1rem;
    border-radius: 50px;
    color: white;
    border: 1px solid white;
    background-color: #f9ed32;
    text-decoration: none;
    font-size: 1rem;
    margin: 2px;
  }

  hr {
    color: #fff;
    border: #ffffff31 solid 1px;
  }

  Button {
    display: none;
  }

  .btn-login {
    background-color: #f9ed32;
    color: white;
    border: solid 1px #f9ed32;
    border-radius: 25px;
    border: solid 1px white;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease-out;
    text-decoration: none;
    font-size: 0.5rem;
    text-decoration: none;
  }
  
  .btn-register {
    color: white;
    background-color: #f9ed32;
    border: dotted 1px white;
    border-radius: 25px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease-out;
    text-decoration: none;
    font-size: 1rem;
    text-decoration: none;
  }

  .btn-login:hover {
    background-color: #f9ed32;
    color: black;
    padding: 15px;
    text-decoration: none;
  }
  
  .btn-register.nav-links:hover {
    background: #f9ed32;
    color: black;
    padding: 15px;
  }

  .btns-login-mobile{
    display: flex;
  }
}

.submenu-navbar{
  background: #121212;
  position: absolute;
  top: 100px;
  display: flex;
  flex-direction: column;
}