@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  background-color: #EFF3FA;
  color: #666;
}

.boxit-blue {
  color: #424996;
}

.boxit-fucsia {
  color: #E9257D;
}

.boxit-purple {
  color: #935CA2;
}

.boxit-gray {
  color: #d3d3d3;
}

.container {
  max-width: 960px;
}

.row-pointer {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
color: #E9257D;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: white !important;
  background-color: #424996 !important; 
  outline: none !important;
}

/* CUSTOM BUTTON
-------------------------------------------------- */
/* GENERAL */
.btn {
  font-size: 14px;
}

/* BLUE */
.btn-blue {
  background-color: #424996;
  border-color: #424996;
  color: #fff;
}
.btn-blue:focus,
.btn-blue:active,
.btn-blue:active:focus {
  background-color: #252955;
  border-color: #252955;
  box-shadow: 0 0 0 4px #babeee;
  color: #fff;
}
.btn-blue:hover {
  background-color: #2e336a;
  color: #fff;
}
.btn-blue:disabled {
  background-color: #424996;
  color: #fff;  
  border: none;
}
.btn-blue:disabled:hover {
  background-color: #424996;
  color: #fff;  
}
.btn-blue:disabled:active {
  box-shadow: none;
}

/* OUTLINE BLUE */
.btn-outline-blue {
  border-color: #424996;
  color: #424996;
}
.btn-outline-blue:focus,
.btn-outline-blue:active,
.btn-outline-blue:active:focus {
  background-color: #2e336a;
  border-color: #2e336a;
  box-shadow: 0 0 0 4px #babeee;
  color: #fff;
}
.btn-outline-blue:hover {
  background-color: #424996;
  color: #fff;
}
.btn-outline-blue:disabled {
  background-color: #fff;
  border-color: #424996;
  color: #424996;
}
.btn-outline-blue:disabled:hover {
  background-color: #fff;
  border-color: #424996;
  color: #424996;
}
.btn-outline-blue:disabled:active {
  box-shadow: none;
}

/* BTN HIDE FUCSIA */
.btn-hide-blue {
  font-size: 13px;
  height: auto;
  padding: 0;
  color: #424996;
}
.btn-hide-blue:hover {
  color: #6d72ac;
}
.btn-hide-blue:active {
  color: #353e8d;
  box-shadow: 0;
}

/* FUCSIA */
.btn-fucsia {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background-color: #E9257D;
  border-color: #E9257D;
  color: #fff;
}
.btn-fucsia:hover {
  background-color: #b11d5f;
  border-color: #b11d5f;
  color: #fff;
}
.btn-fucsia:focus{
  box-shadow: 0 0 0 4px #ebadc9;
}
.btn-fucsia:active {
  background-color: #821546;
  border-color: #821546;
  box-shadow: 0 0 0 4px #ebadc9;
  color: #fff;
}
.btn-fucsia:disabled {
  background-color: #E9257D;
  border: none;
}
.btn-fucsia:disabled:hover {
  background-color: #E9257D;
  border: none;
}
.btn-fucsia:disabled:active {
  box-shadow: none;
}

/* OUT LINE FUCSIA */
.btn-outline-fucsia {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-color: #E9257D;
  background-color: #FFF;
  color: #E9257D;
}
.btn-outline-fucsia:hover {
  background-color: #E9257D;
  color: #fff;
}
.btn-outline-fucsia:focus {
  box-shadow: 0 0 0 4px #ebadc9;
  color: #E9257D;
}
.btn-outline-fucsia:active {
  background-color: #821546;
  border-color: #821546;
}
.btn-outline-fucsia:focus:hover {
  color: #fff;
}
.btn-outline-fucsia:disabled {
  border-color: #E9257D;
  background-color: #FFF;
  color: #E9257D;
}
.btn-outline-fucsia:disabled:hover {
  border-color: #E9257D;
  background-color: #FFF;
  color: #E9257D;
}
.btn-outline-fucsia:disabled:active {
  box-shadow: none;
}

/* BTN HIDE FUCSIA */
.btn-hide {
  font-size: 13px;
  height: auto;
  padding: 0;
  color: #E9257D;
}
.btn-hide:hover {
  color: #b6115b;
}
.btn-hide:active {
  color: #660732;
  box-shadow: 0;
}


/* FUCSIA */
.btn-purple {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background-color: #935CA2;
  border-color: #935CA2;
  color: #fff;
}
.btn-purple:hover {
  background-color: #6d4379;
  border-color: #6d4379;
  color: #fff;
}
.btn-purple:focus{
  box-shadow: 0 0 0 4px #d5afe2;
}
.btn-purple:active {
  background-color: #593763;
  border-color: #593763;
  box-shadow: 0 0 0 4px #d5afe2;
  color: #fff;
}
.btn-purple:disabled {
  background-color: #935CA2;
  border: none;
}
.btn-purple:disabled:hover {
  background-color: #935CA2;
  border: none;
}
.btn-purple:disabled:active {
  box-shadow: none;
}

/* OUT LINE FUCSIA */
.btn-outline-purple {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-color: #935CA2;
  background-color: #FFF;
  color: #935CA2;
}
.btn-outline-purple:hover {
  background-color: #935CA2;
  color: #fff;
}
.btn-outline-purple:focus {
  box-shadow: 0 0 0 4px #d5afe2;
  color: #935CA2;
}
.btn-outline-purple:active {
  background-color: #593763;
  border-color: #593763;
}
.btn-outline-purple:focus:hover {
  color: #fff;
}
.btn-outline-purple:disabled {
  border-color: #935CA2;
  background-color: #FFF;
  color: #935CA2;
}
.btn-outline-purple:disabled:hover {
  border-color: #935CA2;
  background-color: #FFF;
  color: #935CA2;
}
.btn-outline-purple:disabled:active {
  box-shadow: none;
}

/* CUSTOM TOOLTIP
-------------------------------------------------- */
.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .custom-tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: white;
  text-align: center;
  border: 1px solid #E9257D;
  border-radius: 6px;

  /* Position the tooltip */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -175px;
  z-index: 1;
}

@media only screen and (max-width: 576px) {
  .custom-tooltip .custom-tooltiptext {
    margin-left: -190px;
  }
}

.custom-tooltip:hover .custom-tooltiptext {
  visibility: visible;
}

.dashboardCard {
  padding: 20px;
  margin: 15px;
  background: #FFFFFF;
  color: #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.139);
  border-radius: 11px;
  text-align: center;
}

.dashboardCardAltern {
  padding: 20px;
  margin: 15px;
  background: #000000;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.139);
  border-radius: 11px;
  text-align: center;
  border-left: 6px solid #F9ED32;
}

/* Rectangle 22 */




.darkCard {
  padding: 20px;
  background-color: #303141;
  margin: 15px;
  border: 1px solid #bababa;
  box-shadow: 0 0 20px 1px #cacaca;
  border-radius: 30px;
}

.dashboardCard2 {
  background-color: #fff;
  height: 100%;
  min-width: 100%;
  border: 1px solid #bababa;
  box-shadow: 0.1rem 0.1rem 0.5rem #cacaca;
}

/* CUSTOM FONT
-------------------------------------------------- */
.font-xxsmall {
  font-size: 8px;
}

.font-xsmall {
  font-size: 10px;
}

.font-small {
  font-size: 14px;
}
.text-on-hold {
  color: #FABB00;
}

.btn-primary-llego{
  background-color: #000000;
  color: #F9ED32;
}

.btn-primary-llego:hover{
  background-color: #000000;
  border: 2px solid #F9ED32;
  color: #F9ED32; 
}

.btn-secondary-llego{
  border: 1px solid #C2C2C2;
  color: #C2C2C2;
}

.btn-secondary-llego:hover{
  background-color: #000000;
  border: 2px solid #F9ED32;
  color: #F9ED32; 
}

.btn-third-llego{
  background-color: #000000;
  color: #ffffff;
}

.btn-third-llego:hover{
  background-color: #000000;
  color: #F9ED32;
}

.btn-yellow-llego{
  font-weight: bold;
  background-color: #F9ED32;
  color: #000000;
}

.btn-yellow-llego:hover{
  background: transparent;
  border: 2px solid #F9ED32;
  color: #F9ED32; 
}

.btn-black-llego{
  font-weight: bold;
  background-color: black;
  color: #ffffff;
}

.btn-black-llego:hover{
  background: black;
  border: 2px solid white;
  color: #F9ED32; 
}

.glass-effect{
  display: inline-block;
  background: rgb(4 0 0 / 40%); /* Color blanco con opacidad */
  border-radius: 15px;
  padding: 10px 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px); /* Aplicar desenfoque */
  border: 1px solid rgba(255, 255, 255, 0.3);
}