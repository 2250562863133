.main-uploader{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed;
    border-color: rgb(210, 210, 210);
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;
    border-radius: 8px;
    padding: 20px 50px;
    color: white;
    background-color: black;
}