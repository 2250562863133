.reset-form {
    max-width: 100%;
    width: 600px;
    margin: auto;
    background-color: #eeeeee;
    border: 1px solid;
    border-radius: 46px;
    padding: 2em;
    border-color: #fdf3ef;
}

@media (max-width: 630px) {
  .reset-form {
    padding-top: 2em;
    margin: 20px;
  }
}

/* CUSTOM BUTTON */
.btn-reset {
  font-size: 15px;
  font-weight: 600;
  min-height: 40px;
  min-width: 150px;
  margin: 0.5em;
}

#span-eye-pwd:hover, #span-eye-pwdConfirm:hover {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}