
:root{
  --bg-shape-color:linear-gradient(120deg,  #343A4F, #0F1620) ;
 --lightblue: #F9ED32;
 --darkblue: #f0c800;
 --text-color: #D5E1EF;
}

.item-package{
    color: var(--text-color);
  border-radius: 40px;
  background-image: var(--bg-shape-color);
  overflow: hidden;
  max-width: 450px;
  height: 100%;
}
.overviewInfo, .productSpecifications {
  padding: 14px;
}
.overviewInfo{
  background-image:  linear-gradient(176deg, var(--lightblue), var(--darkblue));
}

.overviewInfo-borrador{
  padding: 14px;
  background: #a5a5a5;
}

.actions{
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  
  .cartbutton{
    position:relative;
  }
  .cartbutton::after {
      content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-image: linear-gradient(90deg, var(--lightblue), var(--darkblue));
    border-radius: 50%;
    position: absolute;
    top: 11px;
    right: 8px;
}
  
  .cartbutton svg {
    color: #ababab73;
}
  .backbutton, .cartbutton{
    
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
.neurobutton{
  background-image: var(--bg-shape-color);
   
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 3px 4px 5px 0px rgba(197, 197, 197, 0.1), inset 3px 6px 6px 5px rgba(78, 77, 77, 0.1), -2px -2px 8px 2px rgba(255,255,255,0.1), 2px 2px 6px 3px rgba(0,0,0,0.4);
}

.productinfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    min-height: fit-content;
    margin-bottom: 50px;
}

.productImage {
    position: absolute;
  
    width: 321px;
    height: auto;
    transform: rotate(-90deg) translate(-56px, 66px);
  
 animation: updowncontroller infinite 3s alternate;
img{ width: 100%; height: auto}
  transition: ease 2s all ;
  &:hover{
      transition: ease 2s all ;
    animation: none;
    transform: rotate(-70deg) translate( 10px, 66px);
  }
}
 

h1{
  font-family: 'Michroma', sans-serif;
}
.grouptext{
h3 {
    letter-spacing: 3.2px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}
  p {
    font-size: 12px;
    opacity: 0.8;
  } 
  
}


/* product specifications */
.feature{
    display: flex;
    align-items: center;
    padding: 0 5%;
    margin-bottom: 8px;
}

.feature-title{
    display: flex;
    gap: 3px;
    align-items: center;
    flex-direction: row;
}

.featureIcon{
  width: 40px;
  height: 40px;
  background-image: var(--bg-shape-color);
  border-radius: 8px; 
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.productSpecifications{

  h1{
    margin-top: 10px; 
    margin-bottom: 16px;
    font-size: 32px;
  }
  
  p {
    opacity: 0.8;
    font-size: 15px;
    line-height: 1.5;
}
  
  .productFeatures {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
  }
}

.productSpecifications{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 10%;
}



.checkoutButton {
  width: 100%;
  background-image: var(--bg-shape-color);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: -2px -2px 2px 0px rgba(80, 80, 80, 0.1), 2px 2px 3px 0px rgba(12, 12, 12, 0.3), inset 0px 0px 0px 2px rgba(80, 80, 80, 0.2);
  padding: 14px;
}

.priceTag {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.priceTag span {
    font-size: 14px;
}


/* checkout button*/
.preorder{
    display: flex;
    align-items: center;
    justify-content: center;
}

button.preorder {
    outline: 0;
    border: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-image: linear-gradient(85deg, var(--lightblue), var(--darkblue));
    color: #0F1620;
}

button.preorder:hover {
 border: 1px solid #f0cc00;
}


.preorder {
    padding: 8px 17px;
    border-right: 1px solid rgba(0,0,0,0.4);
    color: black;
}

.buttonaction {
    border-left: 1px solid rgba(255,255,255,.2);
    padding: 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255,255,255,0.7);
}




/* animation */

@keyframes updowncontroller{
  0%  {
    transform: rotate(-90deg) translate(-56px, 66px);}
  80%, 100%{
    transform: rotate(-70deg) translate( 10px, 66px);}
}

.expandButton:hover{
  color: #f0c800;
  cursor: pointer;
}