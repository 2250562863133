a:hover{
  text-decoration: none;
}

footer{
  background: black;
  color: white;
  padding: 40px 10%;
}
.footer-ul{
  list-style-type: circle; 
}

.footer-ul a li{
  font-size: medium;
  color: #b1b1b1;
  margin-bottom: 12px;
}

.footer-items-li{
  font-size: medium;
  color: #b1b1b1;
  margin-bottom: 12px;
}

.footer-items-li:hover{
  cursor: pointer;
  color: #f9ed32;
}

.footer-ul a li:hover{
  cursor: pointer;
  color: #f9ed32;
}

.social-media svg{
  color: #b1b1b1;
}

.social-media svg:hover{
  cursor: pointer;
  color: #f9ed32;;
}

hr{
  background-color: #b1b1b17c;
}

.titles-footer{
  color: #f9ed32;
}