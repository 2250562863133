.dashboard {
  background-color: #EFF3FA;
  text-align: center;
}

.loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #E9257D;
  font-size: 55px;
}

.main-layout-open {
  position: fixed;
  top: 60px;
  left: 80px;
  margin: 0;
  padding: 0;
  width: calc(100% - 80px);
  height: calc(100vh - 60px);
  transition: 650ms;
  overflow-y: auto;
  overflow-x: hidden;
}
.main-layout {
  position: fixed;
  top: 60px;
  left: 80px;
  margin: 0;
  padding: 0;
  width: calc(100% - 80px);
  height: calc(100vh - 60px);
  transition: 650ms;
  overflow-y: auto;
  overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
  .main-layout-open {
    position: fixed;
    top: 60px;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: calc(100vh - 60px);
    transition: 650ms;
    opacity: 0.5;
    overflow-y: auto;
  }
  .main-layout {
    position: fixed;
    top: 60px;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: calc(100vh - 60px);
    transition: 650ms;
    overflow-y: auto;
  }
}

