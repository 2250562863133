.btn-traductor{
    background-color: #000000;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: #F9ED32 ;
}

.btn-traductor:hover{
    background-color: #f8f9fa;
    color: #000;
    border-color: #f8f9fa;
    box-shadow: 0 0 0 0.2rem rgba(248,249,250,.5);
    cursor: pointer;
}

.modal-custome{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.735);
    color: white;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    z-index: 999;
}

.modal-custome-content{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.modal-custome-card{
    background-color: #FFFFFF;
    color: #576AA4;
    padding: 20px 40px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-size: 16px;
    font-weight: bold;
}

.modal-custome-card:hover{
    border-color: #f8f9fa;
    color: rgb(0, 21, 255);
    box-shadow: 0 0 0 0.2rem rgba(248,249,250,.5);
    cursor: pointer;
}

.modal-custome-card img{
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
}

@media (max-width: 610px) {
    .modal-custome-content {
      flex-direction: column; 
    }
  
    .modal-custome-card {
      width: 80%;
      margin: 15px auto;
      padding: 20px 40px;
      flex-direction: row;
      justify-content: space-around; 
    }

    .modal-custome-card span{
        font-size: 16px;
    font-weight: bold;
      }
  }

  .btn-traductor-nav{
    padding: 8px;
    border-radius: 50px;
}

.arrow-down{
    background-color: #e4e4e482;
    padding: 4px;
    border-radius: 50%;
    fill: #424996;
}

.name-translate{
    font-weight: 600;
    color: #F9ED32;
}

.drop-traduct > .dropdown-item:hover{
    cursor: pointer;
    background: #007bff;
    color: white;
}


.dropdown-item:hover{
    cursor: pointer;
    background: #000000;
    color: #f9ed32;
}