.container-stepsLocations {
    background-color: #85CDE4;
    padding: 2rem 4rem ;
    position: relative;
    overflow: hidden;
}

.title-stepsLocations {
    color: black;
    font-size: 5.5rem;
    font-weight: bolder;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 50;
    filter: drop-shadow(8px 4px 4px rgb(133, 205, 228, 0.5));
}

.content-section {
    padding: 0 2rem;
    position: relative;
    z-index: 10;
    margin-top: 0;
}

.text-section p {
    color: black;
    font-size: 1.875rem; /* 30px */
}

.btn-black-llego {
    font-size: 1.5625rem; /* 25px */
}

.highlight {
    color: #7074DC;
}

.nubes_stepsbuy {
    position: absolute;
}

#nube1_stepsplans {
    top: -80px;
    left: -400px;
    height: 400px;
}

#nube2_stepsplans {
    bottom: -80px;
    left: -20px;
    height: 200px;
}

#nube3_stepsplans {
    top: 100px;
    right: -390px;
    height: 230px;
}

.locker-image {
    max-width: 100%;
    height: 500px;
}



#hoja_box_locations{
    top: 40%;
    right: 45%;
    height: 3rem;
  }

#hoja2_box_locations{
    top: 65%;
    right: 55%;
    height: 4.5rem;
  }

  #box1_locations{
    top: 55%;
    right: 45%;
    height: 5rem;
  }

  #hoja3_box_locations{
    top: 65%;
    left: -15px;
    height: 3rem;
  }

  #box2_locations{
    top: 72%;
    right: 2%;
    height: 3rem;
  }

  #hoja4_box_locations{
    top: 85%;
    left: 1%;
    height: 3rem;
    z-index: 5;
  }

  .hojas_locations{
    position: absolute;
}

#btn-locations-llego{
  font-size:40px;
  padding:0 10px;
  border-radius:16px
}

/* Estilos para pantallas de tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .title-stepsLocations {
        font-size: 2.5rem;
    }

    #nube1_stepsplans {
        top: -55px;
        left: -25px;
        height: 130px;
    }

    #nube2_stepsplans {
        bottom: -100px;
        left: -20px;
        height: 200px;
    }

    #nube3_stepsplans {
        top: 100px;
        right: -390px;
        height: 230px;
    }

     /* HOJAS Y CAJAS ABSOLUTE */
     #hoja_box_locations{
        top: 5%;
        right: 5%;
        height: 3rem;
      }
    
    #hoja2_box_locations{
        top: 35%;
        left: -5%;
        height: 3rem;
      }
    
      #box1_locations{
        top: 5%;
        left: 2%;
        height: 4rem;
      }
    
      #hoja3_box_locations{
        display: none;
      }
    
      #box2_locations{
        top: 50%;
        right: 75px;
        height: 3rem;
      }
    
      #hoja4_box_locations{
       display: none;
      }

      #btn-locations-llego{
        font-size:25px;
        padding:10px 10px;
        border-radius:16px;
        margin-bottom: 25px
      }

      .locker-image{
        height: auto;
      }
}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
    .title-stepsLocations {
        font-size: 2.5rem;
        padding: 0 20px;
        margin-bottom: 25px;
    }

    .content-section {
        padding: 0 1rem;
    }

    .text-section p {
        font-size: 1.25rem; /* 20px */
    }

    .btn-black-llego {
        font-size: 1.25rem; /* 20px */
    }

    #nube1_stepsplans {
        top: -45px;
        left: -35px;
        height: 130px;
    }

    #nube2_stepsplans {
        bottom: -100px;
        left: -20px;
        height: 200px;
    }

    #nube3_stepsplans {
        top: 150px;
        right: -500px;
        height: 230px;
    }

    /* HOJAS Y CAJAS ABSOLUTE */
    #hoja_box_locations{
        top: 5%;
        right: 5%;
        height: 3rem;
      }
    
    #hoja2_box_locations{
        top: 35%;
        left: -5%;
        height: 3rem;
      }
    
      #box1_locations{
        top: 5%;
        left: 2%;
        height: 3rem;
      }
    
      #hoja3_box_locations{
        display: none;
      }
    
      #box2_locations{
        top: 55%;
        right: 3%;
        height: 3rem;
      }
    
      #hoja4_box_locations{
       display: none;
      }
    
      #btn-locations-llego{
        font-size:25px;
        padding:10px 10px;
        border-radius:16px;
        margin-bottom: 25px
      }

      .locker-image{
        height: auto;
      }
    
}
