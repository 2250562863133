#section-benefits{
    background-color: #F9ED32;
    padding: 20px 0;
    overflow: hidden;
}

.title-benefits{
    color: #000000;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
}


.logoTitle_llego_benefits{
    height: 130px;
}

.container-list-benefits{
    padding: 0 10%;
}

.cont-item-benefits{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    gap: 20px;
    padding: 25px;
    margin-bottom: 20px;
    text-align: center;
}

.cont-item-benefits img{
    height: 12rem;
    z-index: 5;
}

.hoja_llego_benefits{
    display: flex;
}

#hoja1_benefits{
    height: 3rem;
    margin-left: 25px;
}

#hoja2_benefits{
    height: 2rem;
    transform: scaleX(-1);
    margin-top: 15px;
}

#hoja3_benefits{
    height: 4rem;
    margin-left: 5px;
}

#hojasA_benefits{
    position: absolute;
    top: 45px;
    left: 170px;
}


#hojasB_benefits{
    position: absolute;
    top: -45px;
    right: -50px;
    transform: rotate(-180deg);
}

#nube1_benefits{
    position: absolute;
    top: 65px;
    left: 60px;
    height: 100px;
    z-index: 1;
}

#nube2_benefits{
    position: absolute;
    bottom: 65px;
    left: 60px;
    height: 65px;
    z-index: 1;
}

#nube3_benefits{
    position: absolute;
    top: 65px;
    right: 60px;
    height: 65px;
    z-index: 1;
}

/* Estilos para pantallas de escritorio y laptops */
@media only screen and (min-width: 1024px) {
    .title-benefits{
        font-size: 3.5rem;
        display: flex;
        align-items: center;
    }

    .benefits-titles-section{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    
    }
}

.title-text-benefits{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

/* Estilos para tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .title-benefits{
        font-size: 5rem;
    }

    .title-text-benefits{
        font-size: 3.0rem;
    }

    
    .logoTitle_llego_benefits{
       height: auto;
    }

    .cont-item-benefits img{
        height: 10rem;
        position: relative
    }

    .cont-item-benefits{
        font-size: 16px;
    }

    #hojasA_benefits{
        top: 45px;
        left: 0px;
    }

    #nube1_benefits{
        top: 65px;
        left: 60px;
        height: 50px;
    }
    
    #nube2_benefits{
        bottom: 65px;
        left: 60px;
        height: 50px;
    }
    
    #nube3_benefits{
        top: 65px;
        right: 60px;
        height: 50px;
    }

    .logoTitle_llego_benefits{
        height: 130px;
    }
    
    
}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
    .title-benefits{
        font-size: 2.5rem;
    }



    .title-text-benefits{
        display: flex;
        align-items: center;
        justify-content: center;
       flex-direction: column;
       font-size: 2.5rem;
    }

    .benefits-titles-section {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
}

    .logoTitle_llego_benefits{
        margin-top: 0;
        height:  50px;
    }

    .cont-item-benefits img{
        height: 8rem;
    }
    
    .cont-item-benefits{
        font-size: 16px;
    }

    #hojasA_benefits{
        top: -15px;
        left: -45px;
    }

    #nube1_benefits{
        top: 65px;
        left: 60px;
        height: 50px;
    }
    
    #nube2_benefits{
        bottom: 65px;
        left: 60px;
        height: 50px;
    }
    
    #nube3_benefits{
        top: 65px;
        right: 60px;
        height: 50px;
    }

    .logoTitle_llego_benefits{
        height: 130px;
    }
}