.home-subtitle {
  font-size: 16px;
}

.text-bold {
  font-weight: 600;
}

.home-container {
  background-color: #fff;
  margin: 15px;
  border: 1px solid #bababa;
  box-shadow: 0 0 20px 1px #dfdfdf;
}

.graph {
  height: 100%;
  min-width: 100%;
}

.graph-container {
  height: 200px;
  width: 90%;
}

.card-info {
  height: 50%;
  min-height: 100px;
}

.card-info-vertical {
  height: 100%;
  min-height: 150px;
}

.charts-container {
  min-height: 200px;
  min-width: 220px;
}

/* CUSTOM SPINNNER
-------------------------------------------------- */
.td-spinner {
  height: 120px;
}

.axw {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.azi {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

.ajg {
  background-color: rgb(74 222 128 / 0.1);
}

.akr {
  background-color: rgb(251 113 133 / 0.1);
}

.uo {
  flex: none;
}

.ads {
  border-radius: 9999px;
}

.aqm {
  padding: 0.25rem;
}

.ne {
  height: 0.375rem;
}

.rf {
  width: 0.375rem;
}

.ahy {
  background-color: currentColor;
}

.lx {
  display: flex;
  justify-items: left;
}

.yz {
  align-items: left;
}

.zd {
  justify-content: flex-start;
}

.aac {
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
}

@media (min-width: 640px){
  .cbl {
    justify-content: flex-center;
}
}

.axw-online {
  --tw-text-opacity: 1;
  color: rgb(13, 151, 243, var(--tw-text-opacity)); /* Blue color */
}

.azi-offline {
  --tw-text-opacity: 1;
  color: rgb(169, 169, 169, var(--tw-text-opacity)); /* Gray color */
}

.ajg-online {
  background-color: rgba(13, 151, 243, 0.1); /* Light blue background */
}

.akr-offline {
  background-color: rgb(169, 169, 169, 0.1); /* Light gray background */
}

.axw-yellow {
  --tw-text-opacity: 1;
  color: rgb(255, 223, 85, var(--tw-text-opacity)); /* Yellow color */
}

.ajg-yellow {
  background-color: rgba(238, 204, 55, 0.152); /* Light yellow background */
}
