.signup-form {
    max-width: 100%;
    margin-top: 150px;
    width: 500px;
    margin: auto;
    background-color: white;
    border: 1px solid;
    border-radius: 46px;
    padding: 2rem;
    border-color: #fdf3ef;
    filter: drop-shadow(0px 2px 2px rgba(96, 2, 246, 0.332));
}

.title-signup-1 {
    font-size: 32px;
    color: black;
    margin-bottom: -12px;
    margin-left: 22px;
}
.title-signup-2 {
    font-size: 32px;
    color: black;
    margin-left: 22px;
}

.subtitle-signup {
    font-size: 12px;
}

.purple-left-line {
    border-left: solid 2px #424996;
    height: 35px;
}

.fucsia-left-line {
    border-left: solid 2px #e9257d;
    height: 25px;
}

@media (max-width: 30em) {
    .signup-header {
        margin-top: 15px;
    }
    
    .title-signup-1 {
        font-size: 20px;
        color: black;
        margin-bottom: -7px;
        margin-left: 15px;
    }
    .title-signup-2 {
        font-size: 20px;
        color: black;
        margin-left: 15px;
    }
    
    .subtitle-signup {
        font-size: 10px;
    }
    
    .purple-left-line {
        border-left: solid 2px #424996;
        height: 25px;
    }
    
    .fucsia-left-line {
        border-left: solid 2px #e9257d;
        height: 15px;
    }

    .signup-form {
      padding-top: 1em;
      margin: 10px;
    }

    .signup-container {
        width: 100%;
    }
}

/* CUSTOM BUTTON */
.btn-signup {
    font-size: 15px;
    font-weight: 600;
    min-height: 40px;
    min-width: 150px;
}

/* Stepper.css */
.step-head {
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 0 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.stepper-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative; /* Agregar posición relativa */
    z-index: 1;
  }
  
  .step-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #ccc;
  background-color: #eeeeee;
  font-size: 8px;
}
  
  .step-circle.active {
    border-color: #3F4AC5;
    color: #3F4AC5;
  }

  .step-title {
    font-size: 13px;
    color: #CACACC;
    margin-top: -25px;
  }

  .step-title:hover {
    cursor: pointer;
  }

  .step-title.active {
    color: #3f4ac5;
    font-weight: 600;
  }

  .progress-bar{
    background-color: #3F4AC5;
}

.progress-container {
    width: calc(100% - 50px); 
    position: relative;
    top: -10px;
    z-index: 0;
  }

  .progress {
    width: 100%;
    background-color: #D9D9D9;
  }

  
  /*Buttons Plans*/
  .cards-plans{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
  }

    .btn-plan {
      cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        font-size: 15px;
        margin: 10px;
        padding: 25px 30px;
        color: #ADADAD;
        border: 1px solid;
       border-color: #D8D8D8;
       position: relative;
       
    }

    .btn-plan.active{
        color: #3F4AC5;
        font-weight: 600;
        border: 1px solid;
       border-color: #6B73CF;
    }

    .btn-plan-icon{
        width: 27px;
        height: 27px;
        margin-bottom: 10px;
    }

    .option-circle{
      position: absolute;
      top: 7px;
      right: 7px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: solid 1px #D8D8D8;
    }

    .option-circle.active{
      border: solid 5px #3F4AC5;
    }

.form-check-label{
  cursor: pointer;
}


.link-span {
  cursor: pointer;
  color: #343a40; 
}

.link-span:hover {
  color: #0056b3; 
  text-decoration: underline; 
}

/* BLOQUEO DE PLANES */
.btn-plan{
  cursor: not-allowed;
}
.btn-plan.active{
  cursor: pointer;
}

