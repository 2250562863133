/* NAVBAR
-------------------------------------------------- */
.nav {
    background: #242424;
    height: 60px;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 650ms;
    padding-right: 15px;
    
}
.nav-close {
    background: #242424;
    height: 60px;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 100px;
    width: calc(100% - 100px);
    transition: 650ms;
}

.navMenu {
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.bars-div {
    color: #F9ED32;
    border: 1px solid #F9ED32;
    
    /* background-color: #F9ED32; */
    margin: 8px;
    padding: 8px;
    border-radius: 10px;
}
.bars-div:hover {
    color: #000000;
    border-color: #F9ED32;
    background-color: #F9ED32;
    cursor: pointer;
}.bars-div:active {
    color: #424996;
    border-color: #424996;
    background-color: #a1a4bf;
}

.user {
    margin-left: 18px;
    margin-top: 17px;
    margin-right: 10px;
}
.username {
    font-size: 1.2em;
    text-decoration: none;
    margin-top: 1.1em;
    margin-right: 0.5em;
}
.menu-arrow {
    margin-top: 1.6em;
    margin-right: 1.7em;
}
.user-section {
    font-size: 14px;
    width: auto;
    color: #fff;
    cursor: pointer;
    position: relative;
}
.user-section:hover {
    background-color: #5b629b;
}
.user-section:focus {
    background-color: #363c7a;
}

.active>.dropdown-user {
    height: 84px;
    transition: 650ms;
}

.dropdown-user {
    font-size: 14px;
    position: absolute;
    height: 0;
    top: 60px;
    right: 0;
    min-width: 170px;
    text-align: center;
    background-color: #5b629b;
    overflow: hidden;
    transition: 650ms;
}

.user-option-text {
    display: block;
    padding: 10px;
}
.user-option-text, .user-option-text:hover {
    color: #fff;
    text-decoration: none;
}
.nav-option:hover {
    background: #4d5383;
}

.logo-princ-navbar{
    display: none;
}

@media screen and (max-width: 600px) {
    .name-user-navbar{
        display: none;
    }

    .nav {
        background: #000000;
        height: 60px;
        z-index: 10;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0.5;
        transition: 650ms;
    }
    .nav-close {
        background: #000000;
        height: 60px;
        z-index: 10;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        transition: 650ms;
    }

    .navMenu, .navBtn {
        display: none;
    }

    .navLink {
        color: #fff;
        font-size: 1.1em;
    }

    .bars:active {
        color: #eed4cd;
    }

    .username {
        margin-top: 1em;
    }

    .logo-princ-navbar{
        display: block;
    }
}