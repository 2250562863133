.activate-form {
    max-width: 100%;
    width: 400px;
    margin: auto;
    background-color: #eeeeee;
    border: 1px solid;
    border-radius: 46px;
    padding: 3em;
    border-color: #fdf3ef;
    color: black;
}

@media (max-width: 30em) {
    .signin-form {
      padding: 2.5em;
      margin: 20px;
    }
}