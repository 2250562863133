/* -- MAIN CONTENT -- */
main {
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;
   align-items: center;
   height: auto;
   padding-top: 50px;
   padding-bottom: 70px;
}

.main-img {
   width: 50%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: start;
}

.main-img img {
   width: 400px;
}

.maint-content {
   width: 50%;
   height: 100%;
   display: flex;
   justify-content: start;
   align-items: start;
   font-size: 40px;
}

.maint-content-text {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: end;
   font-size: 40px;
   color: #895298;
}

.maint-content-button {
   display: flex;
   justify-content: space-between;
   justify-items: center;
   margin-top: 40px;
   width: 100%;
   font-size: 24px;
}

.main-btn {
   width: 100%;
   padding: 10px;
   margin: 10px;
   border-radius: 50px;
   display: flex;
   justify-content: center;
}

.main-btn.primary {
   background-color: #895298;
   color: white;
}

.main-btn.alternative {
   color: #895298;
   border: 2px solid #895298;
}

/* -- OBJECTIVES --*/
.section-objectives {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   /* 3 columnas en pantallas grandes */
   gap: 30px;
   padding: 0 10% 5px;
   position: relative;
   top: -65px;
}


.item-objective {
   background-color: #895298;
   color: white;
   padding: 20px 50px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   border-radius: 20px;
   background: #A765B9;
}

.item-objective h4 {
   margin-top: 15px;
}

.item-objective p {
   font-weight: 200;
   font-size: 14px;
   line-height: 29px;
   text-align: center;
}

/* Entrega tus ventas en el locker elegido, tu cliente recibirá una notificación con una clave única para retirar. */



.section-benefits{
   padding-top: 40px;
   background-color: #F6F3FF;
   color: #6E437A;
}

.section-benefits-title{
   display: flex;
   justify-items: center;
   justify-content: center;
   flex-direction: column; 
   align-items: center;
}

.section-benefits-delivery{
   display: flex;
   justify-content: center;
   padding: 30px 5%;
}

.content-benefits-delivery{
   padding-left: 5%;
}


.icon-benefits{
   margin-right: 30px;
}

.item-benefits{
   display: flex;
   align-items: center;
   font-size: 22px;
   margin-bottom: 25px;
}

.section-benefits-pickup{
   margin-top: 25px;
   display: flex;
   padding: 70px 10% 40px;
   background-color: white;
}

.content-benefits-pickup{
   padding-right: 5%;
}

.section-banner{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.btn-banner{
   border: 2px solid white;
   padding: 25px;
   border-radius: 50px;
   color: white;
   font-size: large;
   margin-top: 15px;
}


.btn-banner:hover{
   background-color: white;
   color: #895298;
   cursor: pointer;
   box-shadow: 0px 4px 11px rgba(242, 242, 242, 0.31);
}

.section-testimonials{
   padding: 3% 5%;
}

@media (max-width: 860px) {

   .section-objectives {
      padding: 30px 10%;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
   }

   main {
      display: flex;
      justify-content: center;
      justify-items: center;
      width: 100%;
      height: auto;
      padding-top: 0;
      padding-bottom: 25px;
      font-size: larger;
   }

   .maint-content {
      display: flex;
      justify-items: center;
      width: 100%;
      justify-content: center;
   }

   .maint-content-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0;

      font-size: 38px;
   }

   .maint-content-text span {
      font-size: 28px;
   }

   .maint-content-button {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      justify-items: center;
      margin-top: 0;
      width: 85%;

   }

   .maint-content-button .main-btn {
      font-size: 20px;
   }

   .main-img {
      display: flex;
      justify-items: center;
      margin-top: 25px;
   }

   .main-img img {
      width: 250px;
   }

   .item-objective {
      padding: 20px;
   }

   .title-benefits{
      font-size: 25px;
      text-align: left;
   }

   .item-benefits{
      font-size: 20px;
   }

   .section-testimonials{
      padding: 6% 5%;
   }

   .section-testimonials .title-benefits{
      margin-bottom: 25px;
      text-align: center;
   }

   .section-banner{
      font-size: 16px;
   }

   .title-banner{
      font-size: 20px;
   }

   .btn-banner{
      font-size: 14px;
      padding: 16px;
   border-radius: 50px;
   margin-top: 16px ;
   }

}

.image-hover {
   transition: transform 0.3s;
 }
 
 .image-hover:hover {
   transform: scale(1.1);
 }
 
 .image-clicked {
   box-shadow: 0 0 0 5px white;
   border-radius: 15px; 
   padding: 10px;
 }

 .image-clicked:hover {
   cursor: pointer
 }