.banner-container {
    background: #000;
    position: relative;
    width: 100%;
    padding: 1em 6em;
    text-align: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  

  @keyframes floatAnimation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .floating-element {
    animation: floatAnimation 3s ease-in-out infinite;
  }

  
  .floating-element-slow {
    animation: floatAnimation 5s ease-in-out infinite;
  }