.card-pricing{
   background-color: white;
   padding: 35px;   
   /* border: 1px solid gray; */
   border-radius: 35px;
  box-shadow: 0px 4px 11px rgba(221, 221, 221, 0.639);
  filter: drop-shadow(0px 2px 2px rgba(96, 2, 246, 0.296));
}

.img-pricing{
   border-radius: 35px;
}

.tittle-pricing{
   color: rgb(82, 82, 82);
}

.description-pricing{
   color: rgb(149, 149, 149);
   font-size: medium;
   margin-bottom: 15px;
}

.section-pricing{
margin: 50px 20%;
}

.list-descriptions{
   list-style: none;
   padding: 0;
}

.btn-pricing {
   margin-top: 20px;
   color: white;
   background-color: #935CA2;
   border: none;
   border-radius: 25px;
   padding: 0.5rem 1rem;
   cursor: pointer;
   transition: all 0.2s ease-out;
   text-decoration: none;
   font-size: 1rem;
   width: 100%;
 }

 .sect-details{
   padding-left: 40px;
 }

 .icon-check-1{
   color: #935CA2;
   font-size: 20px;
   font-weight: bold;
 }

 .icon-check-2{
   color: #AE2CD2;
   font-size: 20px;
   font-weight: bold;
 }

 .tittle-pricing{
   color: #935CA2;
 }

 @media (max-width: 860px) {
  .section-pricing{
    margin: 30px 10%;
    }

    .sect-details{
      padding-left: 0;
    }

    .tittle-pricing{
      padding-top: 25px;
      text-align: center;
   }
  
   .cost-pricing{
    text-align: center;
    justify-content: center;
   }

   .img-pricing{
    width: 100%;
    height: 150px;
   }

   .btn-pricing {
    display: block;
  }
}