/* styles.css */

.bannerOpen-container {
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 6em 0;
  overflow: hidden;
}

.bannerOpen-relative-container {
  position: relative;
}

.bannerOpen-pajarito {
  position: absolute;
  top: -15px;
  left: 250px;
}

.bannerOpen-pajarito-img {
  height: 50px;
}

.bannerOpen-nube2 {
  position: absolute;
  top: 32px;
  left: 250px;
}

.bannerOpen-nube2-img {
  height: 50px;
}

.bannerOpen-custom-button {
  font-size: 25px;
  background: #f9ed32;
  font-weight: bold;
  color: black;
}

.bannerOpen-casita {
  position: absolute;
  top: -25px;
  right: 230px;
}

.bannerOpen-casita-img {
  height: 95px;
  position: relative;
  z-index: 2;
  transform: scaleX(-1);
}

.bannerOpen-nube1 {
  position: absolute;
  top: -60px;
  right: 150px;
}

.bannerOpen-nube1-img {
  height: 120px;
  position: relative;
  top: 10px;
  z-index: 1;
}

.bannerOpen-custom-button{
  position: relative;
  z-index: 10;
  box-shadow: rgba(29, 29, 29, 0.9) 0px 7px 29px 0px;
    backdrop-filter: blur(10px);
}

.bannerOpen-custom-button:hover{
background: rgba(255, 255, 255, 0.1);
border: #f9ed32 1px solid;
}


/* Estilos para pantallas de tabletas */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .bannerOpen-pajarito{
    position: absolute;
    top: -15px;
    left: 0px;
}

.bannerOpen-pajarito-img {
  height: 40px;
}

.bannerOpen-nube2 {
  top: 28px;
  left: 0px;
}

.bannerOpen-nube2-img {
  height: 40px;
}

.bannerOpen-casita {
  top: -25px;
  right: 0;
}

.bannerOpen-casita-img {
  height: 75px;
}

.bannerOpen-nube1 {
  top: -60px;
  right: -35px;
}

.bannerOpen-nube1-img {
  height: 65px;
}

}

/* Estilos para smartphones */
@media only screen and (max-width: 767px) {
  .bannerOpen-pajarito{
      position: absolute;
      top: -15px;
      left: 0px;
  }

  .bannerOpen-pajarito-img {
    height: 40px;
  }

  .bannerOpen-nube2 {
    top: 28px;
    left: 0px;
  }

  .bannerOpen-nube2-img {
    height: 40px;
  }

  .bannerOpen-casita {
    top: -25px;
    right: 0;
  }

  .bannerOpen-casita-img {
    height: 75px;
  }

  .bannerOpen-nube1 {
    top: -60px;
    right: -35px;
  }

  .bannerOpen-nube1-img {
    height: 75px;
  }



}
