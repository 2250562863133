.business-signup-date {
    font-size: 11px;
    font-weight: 600;
}

.font-bold {
    font-weight: 600;
}

.btn-update {
    width: 50px;
}

.span-eye-pwd:hover {
    background-color: #d8d8d8;
    border-color: #d8d8d8;
}