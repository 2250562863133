.selector-country{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .card-country{
    display: flex;
    align-items: center;
    padding: 6px 8px;
    margin: 0px 5px;
    border: 1px solid #c6c6c6;
    border-radius: 25px;
    color: #c6c6c6;
  }
  
  .card-country.active{
    background-color: #424996;
     color: white;
     opacity: 1;
     cursor: pointer;
   }
   
  .card-country img{
    width: auto;
    height: 25px;
    margin-right: 5px;
    background-color: #E1E1E1;
    padding: 2px;
    border-radius: 50%;
  }

  /* estilos temporales */
  .card-country{
    border: 0;
background-color: #d7d7d7;
color: rgba(245, 245, 245, 0.737);
opacity: 0.50
  }

  .card-country.active:hover{
    cursor: pointer;
  }

  .card-country.non-selected:hover{
    opacity: 0.75;
    cursor: pointer;
  }
  
  .card-country.disabled{
    opacity: 0.50;
    cursor: not-allowed;
  }

 

 