.search-filters {
  width: 450px;
  margin: 0 auto;
  box-sizing: border-box;
  width: auto;
}

.search-filters-inputs {
  display: flex;
  flex-direction: column;
  position: relative;
}

.search-filters-select {
  width: 150px;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon-container{
  position: absolute;
  z-index: 1;
  left: 15px;
}

.search-icon{
  pointer-events: none;
  color: gray;
}

.search-icon svg{
  color: gray;
}

.search-filters-input {
  flex-grow: 1;
  padding: 10px 15px 10px 40px; /* Ajusta el padding para hacer espacio para el icono */
  border: 1px solid #ccc;
  border-radius: 50px;
  outline: none;
  position: relative;
}


.search-filters-results {
  position: absolute;
  user-select: none;
  top: 40px;
  list-style-type: none;
  padding: 5px 0;
  border: 1px solid #ccc;
  background: white;
  width: 100%;
  z-index: 15;
  border-radius: 8px;
  margin: 0;
  box-shadow: 0 1px 2px rgba(85, 85, 85, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(171, 171, 171, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

.search-filters-results li {
  padding: 5px;
  border-bottom: 1px solid #dddddd8b;
  text-align: left;
  padding-left: 25px;
  border-radius: 4px;
}

.search-filters-results li:hover {
  background-color: #424996;
  color: #ffffff;
  cursor: pointer;
}

.selected-filters{
  display: flex;
  flex-direction: column  ;
  gap: 10px;
}

.selected-filter {
  position: relative;
  margin-top: 10px;
  background: rgb(255, 255, 255);
  padding: 5px 25px;
  background-color: #4249960a;
  width: 100%;
  /* box-shadow: rgba(92, 92, 92, 0.21) 0px 0px 10px; */
  border: 1px solid #d7d7d7;
  border-style: dashed;
  border-radius: 16px;
}

.text-under-ref{
  text-decoration: underline;
}

.text-under-ref:hover{
  cursor: pointer;
  color: #3a49ef;
}