.sidebar-open {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: #000000;
    min-width: 80px;
    max-width: 80px;
    height: 100vh;
    transition: 650ms;
    z-index: 11;
}
.sidebar-close {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: #000000;
    min-width: 80px;
    max-width: 80px;
    height: 100vh;
    transition: 650ms;
}

/* SECCION DEL LOGO */
.logo-section {
    height: 7em;
    border-bottom: 1px solid #636363;
}

.logo-boxit {
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
    transition: 650ms;
}

.close-icon {
    color: #fff;
    font-size: 2rem;
}

.close-icon:hover {
    color: rgb(249, 237, 50);
    font-size: 2rem;
    cursor: pointer;
}


/* SECCION DEL USUARIO */
.usersection {
    margin: 0;
    padding: 0;
    display: block;
    align-content: center;
    height: auto;
    border-bottom: 1px solid #636363;
    transition: 650ms;
}

.userdata {
    opacity: 1;
    visibility: visible;
    display: block;
    transition: 650ms;
}
.userdata-close {
    opacity: 0;
    visibility: hidden;
    display: none;
    transition: 650ms;
}

.user-icon {
    margin: auto;
    transition: 650ms;
}
.user-icon:hover {
    color: #E9257D;
    transition: 650ms;
}

/* SECCION DEL LAS FUNCIONES */
.sidebarWrap {
    width: 100%;
    padding: 0;
}

.sidebar-title {
    color: #9b9b9b;
    font-size: 13px;
}
.sidebar-data {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
}

li {
    list-style-type: none;
}

li.relative{
    position: relative;
}

.copyright {
    position: relative;
    bottom: 0;
}


@media only screen and (max-width: 600px) {
    .sidebar-open {
      background: #000000;
      min-width: 200px;
      max-width: 200px;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      transition: 650ms;
      z-index: 100;
    }
    .sidebar-close {
      background: #303141;
      min-width: 100px;
      max-width: 100px;
      height: 100vh;
      position: fixed;
      top: 0;
      left: -100%;
      transition: 650ms;
      z-index: 100;
    }
}